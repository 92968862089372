import { AccountInfo } from '@insights/models';
import { AlertService } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { chain } from 'lodash';
import { computed, makeObservable, observable } from 'mobx';
import { AppBehaviorAggregationViewModel, BehaviorAggregationViewModel } from './BehaviorAggregationViewModel';
import { BaseExportableViewModel, ExportableViewModel } from './ExportableViewModel';
import { FilteredStudentsProvider } from './FilteredStudentsViewModel';
import {
  CompletesTasksBehaviorProvider,
  FilteredStudentsBehaviorAggregationProvider,
  InvitesParentBehaviorProvider,
  OpensAppBehaviorProvider
} from './StudentsBehaviorProviders';

export interface FilteredStudentsBehaviorViewModel extends ExportableViewModel, FilteredStudentsProvider {
  readonly visibleStudents: AccountInfo[];
  gradeLevelFilter?: string;
  readonly availableGradeLevels: string[];

  readonly opensAppBehavior?: BehaviorAggregationViewModel;
  readonly completesTasksBehavior?: BehaviorAggregationViewModel;
  readonly invitesParentBehavior?: BehaviorAggregationViewModel;
}

export class AppFilteredStudentsBehaviorViewModel
  extends BaseExportableViewModel
  implements FilteredStudentsBehaviorViewModel
{
  private readonly _behaviorAggregationProvider: FilteredStudentsBehaviorAggregationProvider;

  protected readonly _opensAppBehavior: BehaviorAggregationViewModel | undefined;
  private readonly _completesTasksBehavior: BehaviorAggregationViewModel | undefined;
  private readonly _invitesParentBehavior: BehaviorAggregationViewModel | undefined;

  @observable private _gradeLevelFilter: string | undefined;

  constructor(
    localizationService: LocalizationService,
    alertService: AlertService,
    readonly unfilteredStudents: AccountInfo[],
    readonly _parentsById: Record<string, AccountModel[]>
  ) {
    super(localizationService, alertService);
    makeObservable(this);

    this._behaviorAggregationProvider = new FilteredStudentsBehaviorAggregationProvider(this, _parentsById);

    this._opensAppBehavior = new AppBehaviorAggregationViewModel(
      new OpensAppBehaviorProvider(this._behaviorAggregationProvider)
    );
    this._completesTasksBehavior = new AppBehaviorAggregationViewModel(
      new CompletesTasksBehaviorProvider(this._behaviorAggregationProvider)
    );
    this._invitesParentBehavior = new AppBehaviorAggregationViewModel(
      new InvitesParentBehaviorProvider(this._behaviorAggregationProvider)
    );
  }

  @computed
  get students(): AccountInfo[] {
    if (this._gradeLevelFilter != null) {
      return this.unfilteredStudents.filter((s) => s.account.gradeLevel == this._gradeLevelFilter);
    }

    return this.unfilteredStudents;
  }

  @computed
  get visibleStudents(): AccountInfo[] {
    let students = this.students;

    if (this._opensAppBehavior != null && this._opensAppBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.oqProfile != null && this._opensAppBehavior!.activeFilters.includes(s.oqProfile.opensTheApp)
      );
    }

    if (this._completesTasksBehavior != null && this._completesTasksBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.oqProfile != null && this._completesTasksBehavior!.activeFilters.includes(s.oqProfile.marksTasksAsDone)
      );
    }

    if (this._invitesParentBehavior != null && this._invitesParentBehavior.activeFilters.length > 0) {
      students = students.filter(
        (s) => s.invitesParent != null && this._invitesParentBehavior!.activeFilters.includes(s.invitesParent)
      );
    }

    return students;
  }

  get hasStudents(): boolean {
    // Not affected by filtering.
    return this.unfilteredStudents.length > 0;
  }

  @computed
  get gradeLevelFilter(): string | undefined {
    return this._gradeLevelFilter;
  }

  set gradeLevelFilter(value: string | undefined) {
    this._gradeLevelFilter = value;
  }

  @computed
  get availableGradeLevels(): string[] {
    return chain(this.unfilteredStudents.map((s) => s.account.gradeLevel))
      .uniq()
      .sort()
      .value();
  }

  @computed
  get hasFilter(): boolean {
    return this._gradeLevelFilter != null;
  }

  @computed
  get opensAppBehavior(): BehaviorAggregationViewModel | undefined {
    return this._opensAppBehavior;
  }

  @computed
  get completesTasksBehavior(): BehaviorAggregationViewModel | undefined {
    return this._completesTasksBehavior;
  }

  @computed
  get invitesParentBehavior(): BehaviorAggregationViewModel | undefined {
    return this._invitesParentBehavior;
  }

  readonly canExport = true;

  @computed
  get exportTooltip(): string {
    return this._localizationService.localizedStrings.insights.viewModels.metrics.exportStudentsTooltip;
  }

  protected readonly filename = 'Students';

  protected async getExportedData(): Promise<Record<string, string>[]> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.csvExports;

    return Promise.resolve(
      this.students.map((s) => ({
        [strings.id]: s.account.managedIdentifier,
        [strings.lastName]: s.account.lastName,
        [strings.firstName]: s.account.firstName,
        [strings.email]: s.account.email,
        [strings.gradeLevel]: s.account.gradeLevel,
        [strings.opensApp]: s.oqProfile?.opensTheApp ?? '',
        [strings.completesTasks]: s.oqProfile?.marksTasksAsDone ?? '',
        [strings.invitedParent]: s.invitesParent ?? ''
      }))
    );
  }
}
