import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { AccountModel } from '@shared/models/config';
import {
  OnboardingAnswer,
  OnboardingQuestion,
  OnboardingStep,
  OnboardingText
} from '@shared/models/onboarding/interfaces';
import { OnboardingParticipantKind, OnboardingStatus } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { OnboardingStore } from '@shared/services/stores';
import { NavigateFunctionAsync } from '@shared/utils';
import { arrayMoveImmutable } from 'array-move';
import { compact } from 'lodash';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';
import { OnboardingCommentsViewModel } from './OnboardingCommentsViewModel';
import { AppOnboardingQuestionViewModel, OnboardingQuestionViewModel } from './OnboardingQuestionViewModel';
import { DefaultOnboardingVariableResolver, OnboardingVariableResolver } from './OnboardingVariableResolver';

export interface OnboardingStepViewModel {
  readonly id: string;
  readonly templateName: string;
  readonly status: OnboardingStatus;
  readonly targetDate: Date | undefined;
  readonly title: OnboardingText[];
  readonly description: OnboardingText[];
  readonly questions: OnboardingQuestionViewModel[];
  readonly participants: OnboardingParticipantKind;
  readonly isCustomized: boolean;
  readonly isBlocked: boolean;
  readonly isForcedVisible: boolean;

  readonly agent: AccountModel | undefined;
  readonly client: AccountModel | undefined;
  readonly followers: AccountModel[];

  readonly commentsViewModel: OnboardingCommentsViewModel;

  readonly isProcessArchived: boolean;
  readonly canEdit: boolean;
  readonly canSetTargetDate: boolean;
  readonly canComplete: boolean;
  readonly canRepeat: boolean;

  readonly isEveryRequiredQuestionAnswered: boolean;

  readonly isExecuting: boolean;

  readonly variableResolver: OnboardingVariableResolver;

  selectOwners: () => Promise<void>;

  updateStatus(status: OnboardingStatus): Promise<void>;
  reorderQuestion(oldIndex: number, newIndex: number): Promise<void>;
  updateQuestionAnswer(question: OnboardingQuestion, answer: OnboardingAnswer): Promise<boolean>;
  deleteQuestion(question: OnboardingQuestion): Promise<void>;
  completeStep(navigate: NavigateFunctionAsync): Promise<void>;
  repeatStep(): Promise<void>;
  setTargetDate(date: Date): Promise<void>;
  navigateToAddQuestion(): Promise<void>;
  navigateToEditQuestion(question: OnboardingQuestion): Promise<void>;
  renameQuestion(question: OnboardingQuestion): Promise<void>;
  navigateToEditStep(): Promise<void>;
  renameStep(navigate: NavigateFunctionAsync): Promise<void>;
  toggleForcedVisibility(): Promise<void>;
  copyStep(): Promise<void>;
  copyQuestion(question: OnboardingQuestion): Promise<void>;
}

export class AppOnboardingStepViewModel implements OnboardingStepViewModel {
  @observable private _step: OnboardingStep;
  @observable private _isExecuting = false;

  constructor(
    private readonly _onboardingStore: OnboardingStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _settings: SettingsStore,
    private readonly _configId: string,
    private readonly _processName: string,
    private readonly _accountsById: Record<string, AccountModel>,
    step: OnboardingStep,
    readonly commentsViewModel: OnboardingCommentsViewModel,
    readonly isProcessArchived: boolean
  ) {
    makeObservable(this);
    this._step = step;
  }

  @computed
  get id() {
    return this._step.id;
  }

  @computed
  get templateName() {
    return this._step.templateName;
  }

  @computed
  get status() {
    return this._step.status;
  }

  @computed
  get targetDate() {
    return this._step.targetDate;
  }

  @computed
  get title() {
    return this._step.title;
  }

  @computed
  get description() {
    return this._step.description;
  }

  @computed
  get questions(): OnboardingQuestionViewModel[] {
    const hideLockedQuestions = this._settings.onboardingPreferences.hideLockedContent;
    const questions = hideLockedQuestions
      ? this._step.questions.filter((q) => !q.isDependantLocked)
      : this._step.questions;

    return questions.map(
      (q) =>
        new AppOnboardingQuestionViewModel(
          this._onboardingStore,
          this._accountService,
          this._localizationService,
          this._alertService,
          this._configId,
          this,
          q
        )
    );
  }

  @computed
  get participants() {
    return this._step.participants;
  }

  @computed
  get isCustomized() {
    return this._step.isCustomized;
  }

  @computed
  get isBlocked() {
    return this._step.isBlocked;
  }

  @computed
  get isForcedVisible() {
    return this._step.isForcedVisible;
  }

  @computed
  get agent() {
    return this._accountsById[this._step.agentId];
  }

  @computed
  get client() {
    return this._accountsById[this._step.clientId];
  }

  @computed
  get followers(): AccountModel[] {
    return this._step.followerIds.map((id) => this._accountsById[id]);
  }

  @computed
  get canEdit() {
    return (
      !this.isProcessArchived &&
      (this._accountService.isAllowed(['super-admin']) || this._accountService.isAccount(compact([this._step.agentId])))
    );
  }

  @computed
  get canSetTargetDate() {
    return this.canEdit && this._step.status === 'in-progress' && this.targetDate != null;
  }

  @computed
  get canComplete() {
    return this.canEdit || (this.isEveryRequiredQuestionAnswered && !this.isProcessArchived);
  }

  @computed
  get isEveryRequiredQuestionAnswered() {
    return this.questions.find((q) => !q.isDisabled && q.isRequired && !q.answer.hasAnswer) == null;
  }

  @computed
  get canRepeat() {
    return this._step.status === 'completed' && ((this._step.isRepeatable && !this.isProcessArchived) || this.canEdit);
  }

  @computed
  get isExecuting() {
    return this._isExecuting;
  }

  @action
  private setIsExecuting(value: boolean) {
    this._isExecuting = value;
  }

  @computed
  get variableResolver() {
    return new DefaultOnboardingVariableResolver(this._configId);
  }

  async selectOwners(): Promise<void> {
    const result = await this._navigationService.navigateToSelectOwnership(
      this._configId,
      this._processName,
      this._step.clientId,
      this._step.agentId,
      this._step.followerIds,
      this._step.participants
    );

    if (result != 'cancelled') {
      this.setIsExecuting(true);

      try {
        const step = await this._onboardingStore.updateStepAssignees(
          this._step.templateName,
          this._configId,
          this._processName,
          result.clientId,
          result.agentId,
          result.followerIds
        );

        runInAction(() => (this._step = step));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        this.setIsExecuting(false);
      }
    }
  }

  @action
  async updateStatus(status: OnboardingStatus): Promise<void> {
    this.setIsExecuting(true);

    try {
      const step = await this._onboardingStore.updateStepStatus(
        this._step.templateName,
        this._configId,
        this._processName,
        status
      );
      runInAction(() => (this._step = step));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  @action
  async reorderQuestion(oldIndex: number, newIndex: number): Promise<void> {
    this.setIsExecuting(true);

    try {
      let questionNames = this._step.questions.map((q) => q.templateName);
      questionNames = arrayMoveImmutable(questionNames, oldIndex, newIndex);

      const step = await this._onboardingStore.updateStep(
        this._step.templateName,
        this._configId,
        this._processName,
        this._step.participants,
        this._step.title,
        this._step.description,
        this._step.targetDays,
        questionNames,
        this._step.isRepeatable,
        this._step.dependantStepName,
        this._step.dependantQuestionName,
        this._step.dependantQuestionAnswer,
        !this._step.isCustomized
      );

      runInAction(() => (this._step = step));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  async updateQuestionAnswer(question: OnboardingQuestion, answer: OnboardingAnswer): Promise<boolean> {
    // No "isExecuting" for answer updates.
    try {
      const step = await this._onboardingStore.updateAnswer(
        question.templateName,
        this._configId,
        this._step.templateName,
        answer
      );

      runInAction(() => (this._step = step));

      return true;
    } catch {
      return false;
    }
  }

  @action
  async deleteQuestion(question: OnboardingQuestion): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
    const result = await this._alertService.showConfirmation({
      title: strings.confirmDeleteQuestionTitle,
      message: strings.confirmDeleteQuestionMessage,
      okButtonCaption: strings.deleteButtonLabel
    });

    if (result !== 'cancelled') {
      this.setIsExecuting(true);

      try {
        const step = await this._onboardingStore.deleteQuestion(
          question.templateName,
          this._configId,
          this._step.templateName
        );

        runInAction(() => (this._step = step));
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        this.setIsExecuting(false);
      }
    }
  }

  @action
  async completeStep(navigate: NavigateFunctionAsync): Promise<void> {
    if (!this.canComplete) {
      return;
    }

    this.setIsExecuting(true);

    try {
      await this._onboardingStore.updateStepStatus(
        this._step.templateName,
        this._configId,
        this._processName,
        'completed'
      );

      await this._navigationService.navigateToOnboardingProcess(this._configId, this._processName, navigate);
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  @action
  async repeatStep(): Promise<void> {
    if (!this.canRepeat) {
      return;
    }

    this.setIsExecuting(true);

    try {
      const step = await this._onboardingStore.repeatStep(this._step.templateName, this._configId, this._processName);

      runInAction(() => (this._step = step));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  @action
  async setTargetDate(date: Date): Promise<void> {
    if (!this.canSetTargetDate) {
      return;
    }

    this.setIsExecuting(true);

    try {
      const step = await this._onboardingStore.updateStepTargetDate(
        this._step.templateName,
        this._configId,
        this._processName,
        date
      );

      runInAction(() => (this._step = step));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  async navigateToAddQuestion(): Promise<void> {
    const result = await this._navigationService.navigateToAddOnboardingQuestion(
      this._configId,
      this._step.templateName
    );

    if (result !== 'cancelled') {
      runInAction(() => (this._step = result));
      // We assume the newly-added question is always last!
      await this.navigateToEditQuestion(result.questions[result.questions.length - 1]);
    }
  }

  async navigateToEditQuestion(question: OnboardingQuestion): Promise<void> {
    const result = await this._navigationService.navigateToOnboardingQuestionEdition(
      question,
      this._processName,
      this._step.templateName
    );

    if (result !== 'cancelled') {
      runInAction(() => (this._step = result));
    }
  }

  async renameQuestion(question: OnboardingQuestion): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingQuestion(
      question.templateName,
      this._configId,
      this._step.templateName,
      false
    );

    if (result !== 'cancelled') {
      this.setIsExecuting(true);

      try {
        const step = await this._onboardingStore.renameQuestion(
          question.templateName,
          this._configId,
          this._step.templateName,
          result
        );

        runInAction(() => (this._step = step));
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        this.setIsExecuting(false);
      }
    }
  }

  async navigateToEditStep(): Promise<void> {
    const result = await this._navigationService.navigateToOnboardingStepEdition(this._step, this._processName);

    if (result !== 'cancelled') {
      runInAction(() => (this._step = result));
    }
  }

  async renameStep(navigate: NavigateFunctionAsync): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingStep(
      this._step.templateName,
      this._configId,
      false
    );

    if (result !== 'cancelled') {
      this.setIsExecuting(true);

      try {
        // We'll need to rename that step in the process's step list. Load it upfront.
        const process = await this._onboardingStore.getProcess(this._processName, this._configId);

        // Rename the step.
        await this._onboardingStore.renameStep(this._step.templateName, this._configId, result);

        // Update the step name in the process.
        const stepNames = process.steps
          .map((s) => s.templateName)
          .map((name) => (name === this._step.templateName ? result : name));

        await this._onboardingStore.updateProcess(
          this._processName,
          this._configId,
          process.description,
          stepNames,
          !process.isCustomized
        );

        // This renaming affects the page url.
        await this._navigationService.navigateToOnboardingStep(this._configId, this._processName, result, navigate);
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        // Wouldn't be required since we navigate, but stick to the pattern.
        this.setIsExecuting(false);
      }
    }
  }

  async toggleForcedVisibility(): Promise<void> {
    this.setIsExecuting(true);

    try {
      const step = await this._onboardingStore.updateStepVisibility(
        this._step.templateName,
        this._configId,
        this._processName,
        !this.isForcedVisible
      );
      runInAction(() => (this._step = step));
    } catch (error) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
      await this._alertService.showMessage({
        title: strings.unexpectedErrorTitle,
        message: strings.unexpectedError + (error as Error).message
      });
    } finally {
      this.setIsExecuting(false);
    }
  }

  async copyStep(): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingStep(
      this._step.templateName,
      this._configId,
      true
    );

    if (result !== 'cancelled') {
      this.setIsExecuting(true);

      try {
        await this._onboardingStore.createOrUpdateStepTemplate(
          result,
          this._step.participants,
          this._step.title,
          this._step.description,
          this._step.targetDays,
          this._step.questions.map((q) => q.templateName),
          this._step.isRepeatable,
          this._step.dependantStepName,
          this._step.dependantQuestionName,
          this._step.dependantQuestionAnswer
        );
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        // Wouldn't be required since we navigate, but stick to the pattern.
        this.setIsExecuting(false);
      }
    }
  }

  async copyQuestion(question: OnboardingQuestion): Promise<void> {
    const result = await this._navigationService.navigateToRenameOrCopyOnboardingQuestion(
      question.templateName,
      this._configId,
      this._step.templateName,
      true
    );

    if (result !== 'cancelled') {
      this.setIsExecuting(true);

      try {
        await this._onboardingStore.createOrUpdateQuestionTemplate(
          result,
          question.description,
          question.kind,
          question.choices,
          question.isRequired,
          question.dependantQuestionName,
          question.dependantQuestionAnswer,
          question.isHiddenWhenDependant
        );
      } catch (error) {
        const strings = this._localizationService.localizedStrings.insights.viewModels.onboarding;
        await this._alertService.showMessage({
          title: strings.unexpectedErrorTitle,
          message: strings.unexpectedError + (error as Error).message
        });
      } finally {
        this.setIsExecuting(false);
      }
    }
  }
}
