import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { CreateSchoolViewModel } from '@insights/viewmodels';
import { Stack, SxProps } from '@mui/material';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { InsightsButton } from '.';
import { CreateSchoolAccounts } from './CreateSchoolAccounts';
import { CreateSchoolCalendar } from './CreateSchoolCalendar';
import { CreateSchoolGeneral } from './CreateSchoolGeneral';
import { CreateSchoolImports } from './CreateSchoolImports';
import { CreateSchoolOnboarding } from './CreateSchoolOnboarding';
import { CreateSchoolSections } from './CreateSchoolSections';

export interface CreateSchoolProps {
  sx?: SxProps;
  className?: string;
  viewModel: CreateSchoolViewModel;
}

export const CreateSchool = observer(({ sx = [], className, viewModel }: CreateSchoolProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.createSchool;
  const navigate = useNavigateAsync();

  return (
    <Stack sx={sx} className={className}>
      <CreateSchoolGeneral viewModel={viewModel.generalOptions} />
      <CreateSchoolCalendar viewModel={viewModel.calendarOptions} />
      <CreateSchoolAccounts viewModel={viewModel.accountOptions} />
      <CreateSchoolSections viewModel={viewModel.sectionOptions} />
      {viewModel.importOptions.canImportSessions && <CreateSchoolImports viewModel={viewModel.importOptions} />}
      <CreateSchoolOnboarding viewModel={viewModel.onboardingOptions} />
      <Stack
        direction="row-reverse"
        spacing={1}
        sx={{
          padding: 1
        }}
      >
        <InsightsButton
          isDefault
          isDisabled={!viewModel.canSubmit}
          isExecuting={viewModel.isSubmitting}
          onClick={() => void viewModel.createSchool(navigate)}
        >
          {strings.createSchoolButton}
        </InsightsButton>
      </Stack>
    </Stack>
  );
});
