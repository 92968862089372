import {
  AuthorizationRoleCondition,
  ImportSessionDataList,
  ImportSessionFileList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import { AutomatedImportList } from '@insights/components/import/AutomatedImportList';
import DeleteIcon from '@mui/icons-material/Delete';
import { Box, Card, Grid2, IconButton, SxProps, Tooltip, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportSessionDetailsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportSessionDetailsScreen = observer((props: ImportSessionDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, sx = [], style } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;
  const navigate = useNavigateAsync();

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createImportSessionDetails(configId, sessionId),
    [configId, sessionId]
  );

  async function deleteSession() {
    await viewModel.deleteSession();
    void navigate(-1);
  }

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Tooltip title={strings.deleteSessionTooltip}>
            <IconButton aria-label="Add" sx={{ m: 1 }} onClick={() => void deleteSession()}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.session}
          loadingMessage={strings.loadingSessionMessage}
          errorMessage={strings.sessionErrorMessage}
          render={(data) => (
            <Box>
              <Typography
                variant="h5"
                sx={{
                  ml: 1
                }}
              >
                {data.title}
              </Typography>
              <Grid2 container>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Card sx={{ minWidth: MinLayoutWidth }}>
                    <ImportSessionFileList sx={{ width: '100%', height: '100%' }} viewModel={data.fileList} />
                  </Card>
                  <Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedImportList sx={{ width: '100%', height: '100%' }} viewModel={data.automatedImportList} />
                  </Card>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Card sx={{ minWidth: MinLayoutWidth }}>
                    <ImportSessionDataList sx={{ width: '100%', height: '100%' }} viewModel={data.dataList} />
                  </Card>
                </Grid2>
              </Grid2>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
