import {
  CurrentPageInfo,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  WeekPagingNavigation
} from '@insights/components';
import { Box, Card, Grid2, SxProps } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { PublishedTasksByGrade } from './PublishedTasksByGrade';
import { WorkloadManagerByGrade } from './WorkloadManagerByGrade';

export interface WorkloadProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Workload = observer((props: WorkloadProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.workload;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createWorkload(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Workload Manager' });
  });

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ width: '100%', height: '100%' }}
          data={viewModel.data}
          loadingMessage={strings.loadingDataMessage}
          errorMessage={strings.loadingDataErrorMessage}
          render={() => (
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  padding: 2
                }}
              >
                <Grid2 container>
                  {/* Workload Manager */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <WorkloadManagerByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createWorkloadManagerByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>

                  {/* Published Tasks */}
                  <Grid2 size={{ xs: 12 }}>
                    <Card>
                      <PublishedTasksByGrade
                        sx={{ width: '100%', height: '100%' }}
                        viewModel={viewModelFactory.createPublishedTasksByGrade(
                          viewModel.configId,
                          viewModel.pagination
                        )}
                        displayWeekNavigation={false}
                      />
                    </Card>
                  </Grid2>
                </Grid2>
              </Box>

              <PageFooter dense>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <CurrentPageInfo pagination={viewModel.pagination} />

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  <WeekPagingNavigation pagination={viewModel.pagination} />
                </Box>
              </PageFooter>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
