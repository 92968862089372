import { SchoolYearConfigurationScreenViewModel } from '@insights/viewmodels';
import { Button, Stack, SxProps, Typography } from '@mui/material';
import { SchoolYearConfigurationModel } from '@shared/models/config';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SchoolYearConfigurationPowerToolsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: SchoolYearConfigurationScreenViewModel;
  schoolYearConfiguration: SchoolYearConfigurationModel;
}

export const SchoolYearConfigurationPowerTools = observer((props: SchoolYearConfigurationPowerToolsProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, viewModel, schoolYearConfiguration } = props;
  const strings = localizationService.localizedStrings.insights.views.managedGeneral;
  const navigate = useNavigateAsync();

  return (
    <Stack sx={sx} className={className} style={style} spacing={2} maxWidth={500}>
      {/* Clear the cache */}
      <Button
        variant="outlined"
        onClick={() => {
          void viewModel.clearCache();
        }}
      >
        <Typography>{strings.clearCacheLabel}</Typography>
      </Button>

      {/* Create a demo copy */}
      <Button
        variant="outlined"
        onClick={() => {
          void viewModel.createDemoCopy(schoolYearConfiguration);
        }}
      >
        <Typography>{strings.createDemoCopyLabel}</Typography>
      </Button>

      {/* Create a real copy */}
      <Button
        variant="outlined"
        onClick={() => {
          void viewModel.createNextYearCopy(schoolYearConfiguration.id, navigate);
        }}
      >
        <Typography>{strings.createNextYearLabel}</Typography>
      </Button>

      {/* Purge deleted accounts */}
      <Button
        variant="outlined"
        onClick={() => {
          void viewModel.purgeDeletedAccounts(schoolYearConfiguration);
        }}
      >
        <Typography>{strings.purgeDeletedAccountsLabel}</Typography>
      </Button>
    </Stack>
  );
});
