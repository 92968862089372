import { css } from '@emotion/css';
import { BehaviorAggregationViewModel } from '@insights/viewmodels';
import { Box, IconButton, SxProps, Tooltip, Typography, useTheme } from '@mui/material';
import { blue, green, grey, orange, red } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { CSSProperties, ReactElement } from 'react';
import { BehaviorAggregationTooltip, BehaviorAggregationTooltipInfo } from './BehaviorAggregationTooltip.tsx';

export interface BehaviorAggregationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  icon: ReactElement;
  tooltip?: BehaviorAggregationTooltipInfo;
  viewModel: BehaviorAggregationViewModel;
  showEmpty?: boolean;
}

export const BehaviorAggregation = observer((props: BehaviorAggregationProps) => {
  const { sx = [], className, tooltip, style, icon, viewModel, showEmpty = false } = props;
  const { counts } = viewModel;
  const theme = useTheme();

  const countButtonClassName = css({ marginRight: theme.spacing(1) });
  const countBoxClassName = css({
    borderWidth: 3,
    borderStyle: 'solid',
    fontWeight: 700,
    width: 30,
    height: 30,
    verticalAlign: 'middle'
  });
  const borderColor = viewModel.activeFilters.length === 0 ? grey[300] : red[300];
  const backgroundColor = viewModel.activeFilters.length === 0 ? 'white' : red[50];

  return (
    <Box
      sx={{
        ...sx,
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor,
        minWidth: 'min-content'
      }}
      className={className}
      style={style}
      borderRadius={50}
      padding={0.5}
    >
      <Box display="flex" alignItems="center">
        <Tooltip sx={{ mx: 1 }} title={<BehaviorAggregationTooltip tooltip={tooltip} />}>
          {icon}
        </Tooltip>

        {(counts.greyCount != null || showEmpty) && (
          <IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('none')}>
            <Box
              className={countBoxClassName}
              bgcolor={grey[300]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('none') ? blue[500] : grey[300]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption">{counts.greyCount ?? 0}</Typography>
            </Box>
          </IconButton>
        )}
        {(counts.greenCount != null || showEmpty) && (
          <IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('green')}>
            <Box
              className={countBoxClassName}
              bgcolor={green[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('green') ? blue[500] : green[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption" sx={{ color: 'white' }}>
                {counts.greenCount ?? 0}
              </Typography>
            </Box>
          </IconButton>
        )}
        {(counts.yellowCount != null || showEmpty) && (
          <IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('yellow')}>
            <Box
              className={countBoxClassName}
              bgcolor={orange[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('yellow') ? blue[500] : orange[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption" sx={{ color: 'white' }}>
                {counts.yellowCount ?? 0}
              </Typography>
            </Box>
          </IconButton>
        )}
        {(counts.redCount != null || showEmpty) && (
          <IconButton className={countButtonClassName} size="small" onClick={() => viewModel.toggleFilter('red')}>
            <Box
              className={countBoxClassName}
              bgcolor={red[400]}
              borderRadius={50}
              borderColor={viewModel.activeFilters.includes('red') ? blue[500] : red[400]}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="caption" sx={{ color: 'white' }}>
                {counts.redCount ?? 0}
              </Typography>
            </Box>
          </IconButton>
        )}
      </Box>
    </Box>
  );
});
