import { AlertService, NavigationService } from '@insights/services';
import { BlackbaudSkyAuthorizationResult, BlackbaudSkyTokenInformation } from '@shared/models/connectors';
import { LocalizationService } from '@shared/resources/services';
import { BlackbaudSkyConnectorStore } from '@shared/services/stores/interfaces';
import { NavigateFunctionAsync } from '@shared/utils';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface BlackbaudSkyAuthorizationRedirectViewModel {
  readonly configId: string;
  readonly tokens?: BlackbaudSkyTokenInformation;
  readonly hasAccess: boolean;

  navigateToExternalAccounts(navigate: NavigateFunctionAsync): Promise<void>;
}

export interface BlackbaudSkyAuthorizationRedirectScreenViewModel {
  readonly data: IPromiseBasedObservable<BlackbaudSkyAuthorizationRedirectViewModel>;
}

export class AppBlackbaudSkyAuthorizationRedirectViewModel implements BlackbaudSkyAuthorizationRedirectViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    private readonly _authorizationResult: BlackbaudSkyAuthorizationResult
  ) {}

  get configId(): string {
    return this._authorizationResult.configId;
  }

  get tokens(): BlackbaudSkyTokenInformation | undefined {
    return this._authorizationResult.tokens;
  }

  get hasAccess(): boolean {
    return this._authorizationResult.hasAccess;
  }

  async navigateToExternalAccounts(navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToExternalAccounts(this._authorizationResult.configId, navigate);
  }
}

export class AppBlackbaudSkyAuthorizationRedirectScreenViewModel
  implements BlackbaudSkyAuthorizationRedirectScreenViewModel
{
  constructor(
    private readonly _blackbaudSkyStore: BlackbaudSkyConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _state: string,
    private readonly _authenticationCode: string,
    private readonly _error: string
  ) {}

  get data(): IPromiseBasedObservable<BlackbaudSkyAuthorizationRedirectViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<BlackbaudSkyAuthorizationRedirectViewModel> {
    if (this._state.length === 0) {
      throw new Error('Invalid parameter');
    }

    if (this._authenticationCode == 'debug') {
      // A little trick to test different states, kept in production.
      const result: BlackbaudSkyAuthorizationResult = {
        externalAccountId: 'bad',
        configId: 'bad',
        tokens: {
          accessTokenExpiration: new Date(),
          refreshTokenExpiration: new Date(),
          environmentId: 'bad',
          environmentName: 'Debug Environment',
          legalEntityId: 'bad',
          legalEntityName: 'Debug Entity Inc.',
          userId: 'bad',
          email: 'support@studyo.co',
          lastName: 'Doe',
          firstName: 'John'
        },
        hasAccess: true
      };

      return new AppBlackbaudSkyAuthorizationRedirectViewModel(this._navigationService, result);
    }

    const result = await this._blackbaudSkyStore.completeAuthorization(
      this._state,
      this._authenticationCode,
      this._error
    );

    return new AppBlackbaudSkyAuthorizationRedirectViewModel(this._navigationService, result);
  }
}
