import { BehaviorAggregationInfo } from '@insights/models';
import { OQValue } from '@shared/models/types';
import { action, computed, makeObservable, observable } from 'mobx';
import { BehaviorProvider } from './StudentsBehaviorProviders';

export interface BehaviorAggregationViewModel {
  readonly counts: BehaviorAggregationInfo;
  readonly activeFilters: OQValue[];
  toggleFilter: (value: OQValue) => void;
}

export class AppBehaviorAggregationViewModel implements BehaviorAggregationViewModel {
  // None means "no filter applied, show everything"
  @observable private _filters: OQValue[] = [];

  constructor(private readonly _provider: BehaviorProvider) {
    makeObservable(this);
  }

  @computed
  get counts(): BehaviorAggregationInfo {
    return this._provider.behavior;
  }

  @computed
  get activeFilters(): OQValue[] {
    return this._filters;
  }

  @action
  toggleFilter(value: OQValue) {
    if (this._filters.includes(value)) {
      this._filters = this._filters.filter((v) => v !== value);
    } else {
      this._filters = [...this._filters, value];
    }
  }
}
