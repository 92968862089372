import { OnboardingQuestionViewModel, OnboardingStepViewModel } from '@insights/viewmodels';
import { default as CustomizedIcon, default as RenameIcon } from '@mui/icons-material/CallSplit';
import EditIcon from '@mui/icons-material/Edit';
import CopyIcon from '@mui/icons-material/FileCopy';
import ForceVisibleIcon from '@mui/icons-material/Visibility';
import DefaultVisibleIcon from '@mui/icons-material/VisibilityOff';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemIcon,
  ListItemText,
  MenuItem,
  SxProps,
  Tooltip,
  Typography,
  styled
} from '@mui/material';
import { orange } from '@mui/material/colors';
import { useNavigateAsync } from '@shared/utils';
import { ReorderableList } from '@studyo/components';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { MoreActionMenu } from '../MoreActionMenu';
import { DateTimePickerLocalizationProvider } from '../utils';
import { OnboardingOwnership } from './OnboardingOwnership';
import { OnboardingQuestionView } from './OnboardingQuestionView';
import { OnboardingStatusChip } from './OnboardingStatusChip';
import { OnboardingTargetDate } from './OnboardingTargetDate';
import { OnboardingTypographySelector } from './OnboardingTypographySelector';

export interface OnboardingStepCardProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  viewModel: OnboardingStepViewModel;
}

interface SortableQuestionListProps {
  items: OnboardingQuestionViewModel[];
  onOrderChanged: (oldIndex: number, newIndex: number) => void;
}

const SortableQuestionList = ({ items, onOrderChanged }: SortableQuestionListProps) => (
  <Box>
    <ReorderableList
      items={items}
      renderItem={(value) => (
        <OnboardingQuestionView key={`onboarding-question-${value.id}`} viewModel={value} canEdit={true} />
      )}
      onOrderChanged={onOrderChanged}
    />
  </Box>
);

export const OnboardingStepCard = observer((props: OnboardingStepCardProps) => {
  const { localizationService } = useInsightsServices();
  const { className, style, sx = [], viewModel } = props;
  const strings = localizationService.localizedStrings.insights.views.onboarding;
  const navigate = useNavigateAsync();

  return (
    <DateTimePickerLocalizationProvider>
      <Root sx={sx} className={clsx(className, viewModel.isBlocked && 'blockedCard')} style={style}>
        <CardHeader
          title={
            <Box display="flex" flexDirection="row" alignItems="flex-start">
              <OnboardingTypographySelector
                variant="h5"
                texts={viewModel.title}
                variableResolver={viewModel.variableResolver}
              />
              <OnboardingOwnership
                className="ownership"
                agent={viewModel.agent}
                client={viewModel.client}
                followers={viewModel.followers}
                participants={viewModel.participants}
                disabled={!viewModel.canEdit || viewModel.isExecuting}
                onClick={() => viewModel.selectOwners()}
              />
            </Box>
          }
          action={
            <Box display="flex" flexDirection="row" alignItems="center">
              <OnboardingStatusChip
                status={viewModel.status}
                targetDate={viewModel.targetDate}
                isStepStatus
                isBlocked={viewModel.isBlocked}
                onChange={(status) => viewModel.updateStatus(status)}
                disabled={!viewModel.canEdit || viewModel.isExecuting}
              />

              {viewModel.canSetTargetDate && (
                <OnboardingTargetDate
                  targetDate={viewModel.targetDate!}
                  onChange={(date) => viewModel.setTargetDate(date)}
                />
              )}

              {viewModel.isCustomized && viewModel.canEdit && (
                <Tooltip title={strings.customizedStepNotice}>
                  <CustomizedIcon className="customizedIcon" color="error" />
                </Tooltip>
              )}

              {viewModel.canEdit && (
                <MoreActionMenu
                  disabled={viewModel.isExecuting}
                  badgeProps={{
                    variant: 'dot',
                    invisible: !viewModel.isForcedVisible,
                    color: 'primary'
                  }}
                >
                  <MenuItem onClick={() => void viewModel.navigateToEditStep()}>
                    <ListItemIcon>
                      <EditIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.editStepLabel}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.renameStep(navigate)}>
                    <ListItemIcon>
                      <RenameIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.getRenameTemplateLabel(viewModel.templateName)}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.copyStep()}>
                    <ListItemIcon>
                      <CopyIcon />
                    </ListItemIcon>
                    <ListItemText>{strings.copyStepLabel}</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => void viewModel.toggleForcedVisibility()}>
                    <ListItemIcon>
                      {viewModel.isForcedVisible ? <DefaultVisibleIcon color="error" /> : <ForceVisibleIcon />}
                    </ListItemIcon>
                    <ListItemText>
                      {viewModel.isForcedVisible ? strings.toggleForcedVisibilityOff : strings.toggleForcedVisibilityOn}
                    </ListItemText>
                  </MenuItem>
                </MoreActionMenu>
              )}
            </Box>
          }
        />
        <Divider />
        <CardContent>
          <OnboardingTypographySelector
            className="description"
            texts={viewModel.description}
            variableResolver={viewModel.variableResolver}
          />
          {viewModel.canEdit ? (
            <SortableQuestionList
              items={viewModel.questions}
              onOrderChanged={(oldIndex, newIndex) => void viewModel.reorderQuestion(oldIndex, newIndex)}
            />
          ) : (
            <Box>
              {viewModel.questions.map((question, index) => (
                <OnboardingQuestionView key={`onboarding-question-${index}`} viewModel={question} canEdit={false} />
              ))}
            </Box>
          )}

          <Box className="bottomButtonArea" display="flex" flexDirection="row-reverse" padding={1}>
            {viewModel.canRepeat ? (
              <Button
                className="bottomButton"
                variant="contained"
                color="primary"
                disabled={viewModel.isExecuting}
                onClick={() => void viewModel.repeatStep()}
              >
                {strings.repeatStepButton}
              </Button>
            ) : (
              viewModel.status === 'in-progress' && (
                <Button
                  className="bottomButton"
                  variant="contained"
                  color="primary"
                  disabled={!viewModel.canComplete || viewModel.isExecuting}
                  onClick={() => void viewModel.completeStep(navigate)}
                >
                  {strings.completeStepButton}
                </Button>
              )
            )}

            {viewModel.canEdit && (
              <Button
                className="bottomButton"
                variant="contained"
                disabled={viewModel.isExecuting}
                onClick={() => void viewModel.navigateToAddQuestion()}
              >
                {strings.addQuestionLabel}
              </Button>
            )}
          </Box>

          {viewModel.isEveryRequiredQuestionAnswered || (
            <Typography align="right" sx={{ color: (theme) => theme.palette.error.main }}>
              {strings.incompleteAnswersLabel}
            </Typography>
          )}
        </CardContent>
      </Root>
    </DateTimePickerLocalizationProvider>
  );
});

const Root = styled(Card)(({ theme }) => ({
  '.blockedCard': {
    backgroundColor: orange[50]
  },
  '.ownership': {
    marginLeft: theme.spacing(1)
  },
  '.description': {
    marginBottom: theme.spacing(2)
  },
  '.question': {
    display: 'block',
    marginBottom: theme.spacing(1)
  },
  '.editButton': {
    marginLeft: theme.spacing(2)
  },
  '.customizedIcon': {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  '.bottomButtonArea': {
    marginTop: theme.spacing(3)
  },
  '.bottomButton': {
    marginLeft: theme.spacing(1)
  }
}));
