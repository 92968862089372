import { AccountService, NavigationService } from '@insights/services';
import { NavigateFunctionAsync } from '@shared/utils';

export interface LogoutViewModel {
  onInit(navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppLogoutViewModel implements LogoutViewModel {
  constructor(
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService
  ) {}

  async onInit(navigate: NavigateFunctionAsync) {
    try {
      await this._accountService.completeLogout();
      await this._navigationService.redirectToLanding(navigate);
    } catch {
      // Nothing to do.
    }
  }
}
