import { css } from '@emotion/css';
import { alpha, Drawer, List, ListItemButton, SxProps, Typography, useTheme } from '@mui/material';
import { grey } from '@mui/material/colors';
import { EmployeeAuthorizationRoles } from '@shared/models/types';
import clsx from 'clsx';
import { observer } from 'mobx-react-lite';
import { forwardRef } from 'react';
import { NavLink, NavLinkProps } from 'react-router';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from './AuthorizationRoleCondition';
import { DemoIndicator } from './DemoIndicator';
import { Column, Container } from './layout';

const MetricsSideNavigationWidth = 200;

export interface MetricsSideNavigationProps {
  sx?: SxProps;
  className?: string;
  demoMode: boolean;
  configId: string;
}

const SideNavigationLink = forwardRef<HTMLAnchorElement, NavLinkProps>((props, ref) => {
  return <NavLink className={({ isActive }) => clsx(isActive && 'active')} {...props} ref={ref} />;
});
SideNavigationLink.displayName = 'SideNavigationLink';

export const MetricsSideNavigation = observer((props: MetricsSideNavigationProps) => {
  const { environmentService, localizationService, reactRouterRouteService, settingsStore } = useInsightsServices();
  const { sx = [], className, demoMode, configId } = props;
  const routesStrings = localizationService.localizedStrings.insights.routes;
  const theme = useTheme();

  const handleClose = () => {
    settingsStore.sidenavOpen = false;
  };

  const linkClassName = css({
    borderLeftWidth: 4,
    borderLeftStyle: 'solid',
    borderLeftColor: 'transparent',
    paddingLeft: '20px !important',
    '&:hover': {
      borderLeftColor: grey[400]
    },
    '&.active': {
      borderLeftColor: theme.palette.secondary.main,
      backgroundColor: alpha(theme.palette.secondary.main, 0.15),
      '& >:first-of-type': {
        fontWeight: 500
      }
    },
    '&.active:hover': {
      backgroundColor: alpha(theme.palette.secondary.main, 0.3)
    },
    textTransform: 'uppercase',
    height: '50px !important'
  });

  return (
    <Drawer
      sx={{ width: MetricsSideNavigationWidth, ...sx }}
      variant={settingsStore.sidenavCollapsed ? 'temporary' : 'permanent'}
      open={settingsStore.sidenavOpen}
      onClose={handleClose}
    >
      <Column sx={{ flex: 1, width: MetricsSideNavigationWidth, backgroundColor: '#fff' }} className={className}>
        <List sx={{ flex: 1, padding: 0, paddingTop: 9 }}>
          {/* TODO: Add proper AuthorizationRoleCondition to the links */}

          <ListItemButton
            onClick={handleClose}
            component={SideNavigationLink}
            {...{
              to: reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
                {
                  name: RouteParamNames.configId,
                  value: configId
                }
              ])
            }}
            className={linkClassName}
          >
            <Typography
              variant="body2"
              sx={{
                fontWeight: '300'
              }}
            >
              {reactRouterRouteService.getRouteName(RouteTemplates.dashboard, { ...routesStrings })}
            </Typography>
          </ListItemButton>
          <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
            <ListItemButton
              onClick={handleClose}
              component={SideNavigationLink}
              {...{
                to: reactRouterRouteService.resolveLocation(RouteTemplates.workload, [
                  {
                    name: RouteParamNames.configId,
                    value: configId
                  }
                ])
              }}
              className={linkClassName}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '300'
                }}
              >
                {reactRouterRouteService.getRouteName(RouteTemplates.workload, { ...routesStrings })}
              </Typography>
            </ListItemButton>
          </AuthorizationRoleCondition>
          <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
            <ListItemButton
              onClick={handleClose}
              component={SideNavigationLink}
              {...{
                to: reactRouterRouteService.resolveLocation(RouteTemplates.students, [
                  {
                    name: RouteParamNames.configId,
                    value: configId
                  }
                ])
              }}
              className={linkClassName}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '300'
                }}
              >
                {reactRouterRouteService.getRouteName(RouteTemplates.students, { ...routesStrings })}
              </Typography>
            </ListItemButton>
          </AuthorizationRoleCondition>
          <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
            <ListItemButton
              onClick={handleClose}
              component={SideNavigationLink}
              {...{
                to: reactRouterRouteService.resolveLocation(RouteTemplates.teachers, [
                  {
                    name: RouteParamNames.configId,
                    value: configId
                  }
                ])
              }}
              className={linkClassName}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '300'
                }}
              >
                {reactRouterRouteService.getRouteName(RouteTemplates.teachers, { ...routesStrings })}
              </Typography>
            </ListItemButton>
          </AuthorizationRoleCondition>
          <AuthorizationRoleCondition allowedRoles={EmployeeAuthorizationRoles}>
            <ListItemButton
              onClick={handleClose}
              component={SideNavigationLink}
              {...{
                to: reactRouterRouteService.resolveLocation(RouteTemplates.sections, [
                  {
                    name: RouteParamNames.configId,
                    value: configId
                  }
                ])
              }}
              className={linkClassName}
            >
              <Typography
                variant="body2"
                sx={{
                  fontWeight: '300'
                }}
              >
                {reactRouterRouteService.getRouteName(RouteTemplates.sections, { ...routesStrings })}
              </Typography>
            </ListItemButton>
          </AuthorizationRoleCondition>
        </List>

        <Container sx={{ m: 2 }}>
          <Column horizontalContentAlignment="center">
            <Typography
              variant="body2"
              sx={{
                fontWeight: '300',
                fontSize: 10,
                opacity: 0.5
              }}
            >
              {environmentService.formattedVersionNumber}
            </Typography>
          </Column>
        </Container>

        {demoMode && <DemoIndicator />}
      </Column>
    </Drawer>
  );
});
