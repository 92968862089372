import { MinLayoutWidth } from '@insights/Constants';
import {
  AuthorizationRoleCondition,
  AutomatedImportHistory,
  AutomatedTransformationImportList,
  ObservablePresenter,
  PageHeaderBar
} from '@insights/components';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Card, Grid2, IconButton, Stack, SxProps, Tooltip, Typography } from '@mui/material';
import { isSxArray } from '@shared/components/utils';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface AutomatedImportDetailsScreenProps {
  sx?: SxProps;
  className?: string;
}

export const AutomatedImportDetailsScreen = observer(({ sx = [], className }: AutomatedImportDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.views.imports;
  const compStrings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigateAsync();

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';
  const automatedImportId = params.automatedImportId ?? '';

  const viewModel = useMemo(
    () => viewModelFactory.createAutomatedImportDetails(configId, sessionId, automatedImportId),
    [configId, sessionId, automatedImportId]
  );

  return (
    <Box
      className={className}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <Tooltip title={strings.deleteSessionTooltip}>
            <IconButton aria-label="Add" sx={{ m: 1 }} onClick={() => void viewModel.deleteAutomatedImport(navigate)}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </AuthorizationRoleCondition>
      </PageHeaderBar>
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingAutomatedImportMessage}
          errorMessage={strings.loadingAutomatedImportError}
          render={(data) => (
            <Box>
              <Stack
                direction="row"
                spacing={2}
                sx={{
                  ml: 1,
                  alignItems: 'center'
                }}
              >
                <Stack>
                  <Typography variant="h5">{data.automatedImport.name}</Typography>
                  <Typography variant="subtitle1">
                    {compStrings.automatedImportTimes(data.automatedImport.days, data.automatedImport.time)}
                  </Typography>
                </Stack>
                <IconButton onClick={() => void data.editAutomatedImport()}>
                  <EditIcon />
                </IconButton>
              </Stack>
              <Grid2 container>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedTransformationImportList
                      sx={{ width: '100%', height: '100%' }}
                      viewModel={data.transformations}
                    />
                  </Card>
                </Grid2>

                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Card sx={{ minWidth: MinLayoutWidth }}>
                    <AutomatedImportHistory viewModel={data.history} />
                  </Card>
                </Grid2>
              </Grid2>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
