import {
  AuthorizationRoleCondition,
  BehaviourSummary,
  BehaviourSupplement,
  ExpansionPanel,
  ObservablePresenter,
  PageHeaderBar,
  PageHeaderDetailBar,
  SectionList
} from '@insights/components';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { AccordionDetails, AccordionSummary, Box, Button, Card, Grid2, Link, SxProps, Typography } from '@mui/material';
import { AccountUtils, isSxArray } from '@shared/components/utils';
import { AdminOrTeacherAuthorizationRoles } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { StudentBehaviourHistory } from './StudentBehaviourHistory';

const SelectedSectionsCardStateKey = 'StudentDetailsSelectedSections';

export interface StudentDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const StudentDetails = observer((props: StudentDetailsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const params = useParams();
  const configId = params.configId ?? '';
  const studentId = params.studentId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createStudentDetails(configId, studentId), [configId, studentId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Student Details' });
  });

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar>
        <AuthorizationRoleCondition allowedRoles={AdminOrTeacherAuthorizationRoles}>
          <Button onClick={() => void viewModel.navigateToPlanner()}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center'
              }}
            >
              <Typography>{strings.viewPlanner}</Typography>

              <OpenInNewIcon sx={{ ml: 0.5 }} color="action" />
            </Box>
          </Button>
        </AuthorizationRoleCondition>
      </PageHeaderBar>
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingStudentDetailsMessage}
          errorMessage={strings.loadingStudentDetailsErrorMessage}
          render={(data) => (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column'
              }}
            >
              {/* Student Info */}
              <PageHeaderDetailBar>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center'
                  }}
                >
                  <Box>
                    <Typography sx={{ fontSize: '2em', lineHeight: 'normal', fontWeight: 300 }}>
                      {AccountUtils.getDisplayLastFirstName(data.account, strings.noName)}
                    </Typography>
                    <Link variant="body1" target="_top" href={`mailto:${data.account.email}`}>
                      {data.account.email}
                    </Link>
                  </Box>

                  <Box
                    sx={{
                      flex: 1
                    }}
                  />

                  <BehaviourSummary
                    appOpenValue={data.oqProfile?.opensTheApp}
                    taskCompletionValue={data.oqProfile?.marksTasksAsDone}
                  />
                  {data.invitesParent != null && (
                    <BehaviourSupplement sx={{ ml: 2 }} invitesParent={data.invitesParent} />
                  )}
                </Box>
              </PageHeaderDetailBar>

              {/* Content */}
              <Box
                sx={{
                  flex: 1,
                  overflow: 'auto',
                  padding: 2
                }}
              >
                <Grid2
                  container
                  sx={{
                    alignItems: 'stretch'
                  }}
                >
                  {/* Behaviour History */}
                  <AuthorizationRoleCondition allowedRoles={AdminOrTeacherAuthorizationRoles}>
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <StudentBehaviourHistory
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createStudentBehaviourHistory(
                            viewModel.configId,
                            viewModel.studentId
                          )}
                        />
                      </Card>
                    </Grid2>
                  </AuthorizationRoleCondition>

                  {/* Sections */}
                  {data.sections != null && data.sections.sections.length > 0 && (
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <ExpansionPanel
                          sx={{ height: '100%', width: '100%' }}
                          stateKey={SelectedSectionsCardStateKey}
                          elevation={0}
                        >
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="h6">{strings.selectedSections}</Typography>
                          </AccordionSummary>

                          <AccordionDetails sx={{ p: 0 }}>
                            <SectionList
                              sx={{ flex: 1 }}
                              sections={data.sections}
                              usePagination={false}
                              configId={viewModel.configId}
                            />
                          </AccordionDetails>
                        </ExpansionPanel>
                      </Card>
                    </Grid2>
                  )}
                </Grid2>
              </Box>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
