import { AccountInfo, SectionInfo, accountInfoFromModel } from '@insights/models';
import { AlertService } from '@insights/services';
import { getParentInvitationOQValue } from '@insights/utils';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { LocalizationService } from '@shared/resources/services';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppFilteredStudentsBehaviorViewModel,
  FilteredStudentsBehaviorViewModel
} from './FilteredStudentsBehaviorViewModel';
import { AppPaginatedViewModel, AppPaginationViewModel, PaginatedViewModel } from './PaginatedViewModel';

export interface SectionDetailsInfo {
  readonly config: SchoolYearConfigurationSummary;
  readonly sectionInfo: SectionInfo;
  readonly studentsViewModel: FilteredStudentsBehaviorViewModel;
}

export interface SectionDetailsViewModel extends PaginatedViewModel {
  readonly configId: string;
  readonly sectionId: string;
  readonly data: IPromiseBasedObservable<SectionDetailsInfo>;
}

export class AppSectionDetailsViewModel extends AppPaginatedViewModel implements SectionDetailsViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _metricsStore: MetricsStore,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly sectionId: string
  ) {
    super();
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SectionDetailsInfo> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<SectionDetailsInfo> {
    const [config, section, students, teachers, parentsByChildId] = await Promise.all([
      this._schoolYearConfigurationStore.getConfigSummary(this.configId),
      this._schoolYearConfigurationStore.getSection(this.configId, this.sectionId),
      this._schoolYearConfigurationStore.getStudentsForSectionId(this.configId, this.sectionId, false),
      this._schoolYearConfigurationStore.getTeachersForSectionId(this.configId, this.sectionId, false),
      this._schoolYearConfigurationStore.getParentsByChildId(this.configId, false)
    ]);

    this.setPagination(new AppPaginationViewModel(config.startDay, config.endDay));

    const studentIds = _.chain(students)
      .map((student) => student.id)
      .uniq()
      .value();

    const oqProfiles = await this._metricsStore.getAccountOQMetrics(this.configId, studentIds, false);
    const oqProfilesByStudentId = new Map(oqProfiles.map((p) => [p.accountId, p]));

    const studentInfos = students.map<AccountInfo>((account) => ({
      id: account.id,
      account,
      oqProfile: oqProfilesByStudentId.get(account.id)?.oqProfile,
      invitesParent: getParentInvitationOQValue(account, parentsByChildId)
    }));

    return {
      config,
      sectionInfo: {
        id: section.id,
        section,
        students: studentInfos,
        teachers: teachers.map(accountInfoFromModel)
      },
      studentsViewModel: new AppFilteredStudentsBehaviorViewModel(
        this._localizationService,
        this._alertService,
        studentInfos,
        parentsByChildId
      )
    };
  }
}
