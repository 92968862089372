import { AuthorizationRole } from '@shared/models/types';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router';
import { RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { Forbidden } from './Forbidden';

export interface InsightsRouteProps {
  children: ReactNode;
  allowedRoles?: AuthorizationRole[];
  allowRootAdmins?: boolean;
  authenticatedRoute?: boolean;
  hasSidenavBar?: boolean;
}

export const InsightsRoute = observer((props: InsightsRouteProps) => {
  const { authenticatedRoute, allowedRoles, allowRootAdmins = false, hasSidenavBar, children } = props;
  const { accountService, reactRouterRouteService, viewModelFactory, settingsStore } = useInsightsServices();
  const location = useLocation();

  settingsStore.hasSidenav = hasSidenavBar ?? false;

  if (authenticatedRoute) {
    const isLoggedIn = accountService.isLoggedIn;

    if (!isLoggedIn) {
      const loginLocation = reactRouterRouteService.resolveLocation(RouteTemplates.login);
      return <Navigate to={loginLocation} replace state={{ referrer: location }} />;
    }

    const isAuthorized = allowedRoles == null || accountService.isAllowed(allowedRoles);

    // In demo mode, root admins appear as normal admins. Some routes might want to
    // allow them the access anyway.
    if (!isAuthorized && (!allowRootAdmins || !accountService.isRootAdmin)) {
      return <Forbidden viewModel={viewModelFactory.createForbidden()} />;
    }
  }

  return <>{children}</>;
});
