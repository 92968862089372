import { ApplicationName, LoadingIndicator } from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface LogoutProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Logout = observer((props: LogoutProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights;
  const viewModel = useMemo(() => viewModelFactory.createLogout(), []);

  const navigate = useNavigateAsync();

  useEffect(() => {
    void viewModel.onInit(navigate);
  }, []);

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column" alignItems="center">
      <ApplicationName sx={{ marginTop: '20vh' }} size="extra-large" color="light" />
      <LoadingIndicator sx={{ marginTop: '10vh' }} message={strings.loggingOutMessage} />
    </Box>
  );
});
