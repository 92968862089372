import { EditableAccountList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { EditableAccountsScreenViewModel } from '@insights/viewmodels';
import { Box, Card, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface EditableAccountsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  kind: 'teachers' | 'students' | 'parents' | 'staff';
}

export const EditableAccounts = observer((props: EditableAccountsProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, kind } = props;
  const strings = localizationService.localizedStrings.insights.views.account;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo<EditableAccountsScreenViewModel>(() => {
    switch (kind) {
      case 'parents':
        return viewModelFactory.createEditableParents(configId, true);
      case 'students':
        return viewModelFactory.createEditableStudents(configId, true);
      case 'teachers':
        return viewModelFactory.createEditableTeachers(configId, true);
      case 'staff':
        return viewModelFactory.createEditableStaff(configId, true);
    }
  }, [kind, configId]);

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 1, height: '100%', width: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingRecordsMessage}
          errorMessage={strings.loadingRecordsErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card sx={{ minWidth: MinLayoutWidth }}>
                <EditableAccountList
                  sx={{ width: '100%', height: '100%' }}
                  title={strings.accountListTitle(viewModel.manageableRole)}
                  viewModel={data}
                  accountType={viewModel.manageableRole}
                  configId={viewModel.configId}
                />
              </Card>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
