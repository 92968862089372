import { AlertService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { NavigateFunctionAsync } from '@shared/utils';
import { action, computed, makeObservable, observable, runInAction } from 'mobx';

export interface ValidatableViewModel {
  readonly hasChanges: boolean;

  validate(): string[];
}

export interface Editor {
  readonly isApplying: boolean;
  readonly validationMessages: string[];

  apply(navigate: NavigateFunctionAsync): Promise<boolean>;
  cancel(): Promise<void>;
}

export interface ActivatableEditor<TEditableModel, TEditableViewModel extends ValidatableViewModel> extends Editor {
  readonly active?: TEditableViewModel;

  edit(viewModel: TEditableViewModel, originalModel?: TEditableModel): void;
}

export class AppActivatableEditor<TEditableModel, TEditableViewModel extends ValidatableViewModel>
  implements ActivatableEditor<TEditableModel, TEditableViewModel>
{
  @observable private _active?: TEditableViewModel;
  private _originalModel?: TEditableModel;
  @observable private _isApplying = false;
  private _validationMessages = observable.array<string>([]);

  constructor(
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _applyer: (
      viewModel: TEditableViewModel,
      navigate: NavigateFunctionAsync,
      originalModel?: TEditableModel
    ) => Promise<void>,
    private readonly _canceller?: () => Promise<void>
  ) {
    makeObservable(this);
  }

  @computed
  get active() {
    return this._active;
  }

  @computed
  get isApplying() {
    return this._isApplying;
  }

  @computed
  get validationMessages(): string[] {
    return this._validationMessages;
  }

  @action
  edit(viewModel: TEditableViewModel, originalModel?: TEditableModel): void {
    if (this._active != null) {
      throw new Error('Invalid operation: This editor is already editing.');
    }

    this._validationMessages.clear();
    this._originalModel = originalModel;
    this._active = viewModel;
  }

  @action
  async apply(navigate: NavigateFunctionAsync): Promise<boolean> {
    if (this._active == null) {
      throw new Error('Invalid operation. This editor is not editing.');
    }

    this._isApplying = true;
    this._validationMessages.clear();

    try {
      const messages = this._active.validate();

      if (messages.length > 0) {
        this._validationMessages.replace(messages);
        return false;
      } else {
        await this._applyer(this._active, navigate, this._originalModel);
        runInAction(() => (this._active = undefined));
        return true;
      }
    } catch (error) {
      console.error(`Editor apply failed with exception: ${(error as Error).message}`);
      runInAction(() => this._validationMessages.replace([`Unexpected error: ${(error as Error).message}`]));
      return false;
    } finally {
      runInAction(() => (this._isApplying = false));
    }
  }

  async cancel(): Promise<void> {
    let canCancel = true;

    if (this._active?.hasChanges) {
      const strings = this._localizationService.localizedStrings.insights.viewModels.edit;
      const result = await this._alertService.showConfirmation({
        message: strings.pendingChangesMessage,
        okButtonCaption: strings.discardButtonCaption,
        cancelButtonCaption: strings.cancelButtonCaption
      });

      canCancel = result != 'cancelled';
    }

    if (canCancel) {
      runInAction(() => {
        this._active = undefined;
        this._originalModel = undefined;
      });

      if (this._canceller != null) {
        await this._canceller();
      }
    }
  }
}
