import { MaterialTableData } from '@insights/models';
import { NavigationService, SettingsStore } from '@insights/services';
import { SchoolYearConfigurationSummary } from '@shared/models/config';
import { ConfigGlobalStats } from '@shared/models/metrics';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import { NavigateFunctionAsync } from '@shared/utils';
import _ from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface SchoolConfigurationInfo extends MaterialTableData {
  readonly schoolConfiguration: SchoolYearConfigurationSummary;
  readonly schoolGlobalStats: ConfigGlobalStats;
  readonly validationMessages: string[] | undefined;
}

export interface SchoolConfigurationsViewModel {
  schoolConfigurationsYear: number;
  showDemoSchools: boolean;

  readonly data: IPromiseBasedObservable<SchoolConfigurationInfo[]>;

  createNewSchool(navigate: NavigateFunctionAsync): Promise<void>;
  editSchool(configId: string, navigate: NavigateFunctionAsync): Promise<void>;
}

export class AppSchoolConfigurationsViewModel implements SchoolConfigurationsViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _navigationService: NavigationService,
    private readonly _metricStore: MetricsStore
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SchoolConfigurationInfo[]> {
    return fromPromise(
      this.loadData(this._settingsStore.schoolConfigurationsYear, this._settingsStore.showDemoSchools)
    );
  }

  @computed
  get schoolConfigurationsYear(): number {
    return this._settingsStore.schoolConfigurationsYear;
  }

  set schoolConfigurationsYear(value: number) {
    this._settingsStore.schoolConfigurationsYear = value;
  }

  @computed
  get showDemoSchools() {
    return this._settingsStore.showDemoSchools;
  }

  set showDemoSchools(value: boolean) {
    this._settingsStore.showDemoSchools = value;
  }

  async createNewSchool(navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToNewSchool(navigate, undefined);
  }

  editSchool(configId: string, navigate: NavigateFunctionAsync): Promise<void> {
    return this._navigationService.navigateToManageSchool(configId, navigate);
  }

  private async loadData(year: number, demoSchools: boolean): Promise<SchoolConfigurationInfo[]> {
    const schools = await this._schoolYearConfigurationStore.getConfigs(year);
    const filteredSchools = schools.filter((school) => school.isDemo === demoSchools);
    const stats = await Promise.all(filteredSchools.map((s) => this._metricStore.getConfigGlobalStats(s.id)));
    const validationResults = await Promise.all(
      filteredSchools.map((s) => this._schoolYearConfigurationStore.validateConfig(s.id))
    );

    return _.zip(filteredSchools, stats, validationResults).map((group) => ({
      id: group[0]!.id,
      schoolConfiguration: group[0]!,
      schoolGlobalStats: group[1]!,
      validationMessages: group[2]!
    }));
  }
}
