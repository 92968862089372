import { ExternalAssociationList, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, Card, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ExternalAssociationsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ExternalAssociationsScreen = observer((props: ExternalAssociationsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.connectors;

  const params = useParams();
  const configId = params.configId ?? '';
  const externalAccountId = params.externalAccountId ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createExternalAssociations(configId, externalAccountId),
    [configId, externalAccountId]
  );

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', p: 2, overflow: 'auto' }}
          data={viewModel.associationList}
          loadingMessage={strings.loadingExternalAssociationsMessage}
          errorMessage={strings.loadingExternalAssociationsErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card sx={{ minWidth: MinLayoutWidth }}>
                <ExternalAssociationList sx={{ width: '100%', height: '100%' }} viewModel={data} />
              </Card>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
