import { Breadcrumbs, Link, SxProps, Toolbar, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { observer } from 'mobx-react-lite';
import { ReactNode } from 'react';
import { Link as RouterLink, matchPath, useLocation, useParams } from 'react-router';
import { RouteParamNames, RouteTemplates } from '../Routes';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from './AuthorizationRoleCondition';
import { CopyIdButton } from './CopyIdButton';
import { Expanded } from './layout';

export interface PageHeaderBarProps {
  sx?: SxProps;
  className?: string;
  children?: ReactNode;
}

export const PageHeaderBar = observer((props: PageHeaderBarProps) => {
  const { localizationService, reactRouterRouteService } = useInsightsServices();
  const { sx = [], className, children } = props;
  const routesStrings = localizationService.localizedStrings.insights.routes;

  const location = useLocation();
  const params = useParams();
  const configId = params.configId ?? '';
  const hasConfig = configId.length > 0;

  const pathnames = location.pathname.split('/').filter((x) => x);
  // Though this component is not used by the "manage" layout, we want to skip any root.
  const shouldSkipConfigs =
    matchPath(
      {
        path: RouteTemplates.metricsRoot,
        end: false,
        caseSensitive: false
      },
      location.pathname
    ) != null ||
    matchPath(
      {
        path: RouteTemplates.manageRoot,
        end: false,
        caseSensitive: false
      },
      location.pathname
    ) != null;
  const shouldSkipPaths = [
    RouteTemplates.schoolConfigurations,
    RouteTemplates.metricsRoot,
    RouteTemplates.manageOnboardingProcess,
    RouteTemplates.new,
    RouteTemplates.newFrom
  ];

  return (
    <Toolbar variant="dense" sx={{ ...sx, backgroundColor: grey[300], height: 50 }} className={className}>
      <Breadcrumbs maxItems={5} sx={{ mt: 1 }}>
        {/* NOTE: Always add a Home entry */}
        {hasConfig && (
          <Link
            variant="body2"
            component={RouterLink}
            to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
              { name: RouteParamNames.configId, value: configId }
            ])}
          >
            {reactRouterRouteService.getRouteName(RouteTemplates.landing, {
              ...routesStrings
            })}
          </Link>
        )}
        {pathnames
          .map((_, index) => `/${pathnames.slice(0, index + 1).join('/')}`)
          .filter(
            (pathname) =>
              !shouldSkipConfigs ||
              shouldSkipPaths.find((path) => matchPath({ path, end: true, caseSensitive: false }, pathname)) == null
          )
          .map((pathname, index, items) => {
            const isLast = index === items.length - 1;
            const routeName = reactRouterRouteService.getRouteName(pathname, { ...routesStrings });

            if (routeName == null) {
              return null;
            }

            return isLast ? (
              <Typography
                variant="body2"
                key={pathname}
                sx={{
                  fontWeight: '500'
                }}
              >
                {routeName}
              </Typography>
            ) : (
              <Link variant="body2" component={RouterLink} to={{ ...location, pathname: pathname }} key={pathname}>
                {routeName}
              </Link>
            );
          })}
      </Breadcrumbs>
      <Expanded />
      {children}
      {hasConfig && (
        <AuthorizationRoleCondition allowedRoles={['super-admin']}>
          <CopyIdButton id={configId} />
        </AuthorizationRoleCondition>
      )}
    </Toolbar>
  );
});
