import { AccountInfo } from '@insights/models';
import { AccountService, AlertService, NavigationService, SettingsStore } from '@insights/services';
import { getParentInvitationOQValue } from '@insights/utils';
import { LocalizationService } from '@shared/resources/services';
import { MetricsStore, SchoolYearConfigurationStore } from '@shared/services/stores';
import _ from 'lodash';
import { computed, makeObservable, runInAction } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import {
  AppFilteredStudentsBehaviorViewModel,
  FilteredStudentsBehaviorViewModel
} from './FilteredStudentsBehaviorViewModel';
import { FilteredViewModel } from './FilteredViewModel';
import { StudentsFilters } from './StudentsFilterDialogViewModel';

export interface StudentsViewModel extends FilteredViewModel {
  readonly configId: string;
  readonly shouldLimitAccess?: boolean;
  readonly data: IPromiseBasedObservable<FilteredStudentsBehaviorViewModel>;
}

export class AppStudentsViewModel implements StudentsViewModel {
  constructor(
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _metricsStore: MetricsStore,
    private readonly _settingsStore: SettingsStore,
    private readonly _accountService: AccountService,
    private readonly _navigationService: NavigationService,
    private readonly _localizationService: LocalizationService,
    private readonly _alertService: AlertService,
    public readonly configId: string,
    public readonly shouldLimitAccess?: boolean
  ) {
    makeObservable(this);
  }

  @computed
  get filterCount(): number {
    return this.getBehaviourFilterCount();
  }

  @computed
  get data(): IPromiseBasedObservable<FilteredStudentsBehaviorViewModel> {
    return fromPromise(this.loadData());
  }

  @computed
  private get studentsFilters(): StudentsFilters {
    return this._settingsStore.studentsFilters;
  }

  private set studentsFilters(value: StudentsFilters) {
    this._settingsStore.studentsFilters = value;
  }

  async showFilters(): Promise<void> {
    // Note: Though this view-model implements FilteredViewModel, its implementation
    //       is not used anymore, in favor of behavior aggregation filters. It was left
    //       here in case we want to add new kinds of filters.
    const result = await this._navigationService.navigateToStudentsFilters(this.studentsFilters);

    if (result !== 'cancelled') {
      runInAction(() => (this.studentsFilters = result));
    }
  }

  private getBehaviourFilterCount(): number {
    let filterCount = 0;

    if (this.studentsFilters.behaviour.appOpenFilter != null) {
      filterCount++;
    }

    if (this.studentsFilters.behaviour.taskCompletionFilter != null) {
      filterCount++;
    }

    if (this.studentsFilters.behaviour.taskBreakdownFilter != null) {
      filterCount++;
    }

    if (this.studentsFilters.behaviour.planningFilter != null) {
      filterCount++;
    }

    return filterCount;
  }

  private async loadData(): Promise<FilteredStudentsBehaviorViewModel> {
    const filters = this.studentsFilters;

    let students = await this._schoolYearConfigurationStore.getStudents(this.configId, false);

    if (this.shouldLimitAccess === true && !this._accountService.isAllowed(['super-admin', 'admin'])) {
      // For now, we assume it's a teacher. We also assume it's only one!
      const teacherId = this._accountService.getAccountIdForConfigRole(this.configId, 'teacher');

      if (teacherId == null) {
        students = [];
      } else {
        const sections = await this._schoolYearConfigurationStore.getTaughtSectionsForTeacherId(
          this.configId,
          teacherId
        );
        const sectionIds = new Set(sections.map((s) => s.id));
        students = students.filter((student) => student.selectedSectionIds.some((id) => sectionIds.has(id)));
      }
    }

    const studentIds = _.chain(students)
      .map((student) => student.id)
      .uniq()
      .value();

    const [parentsByChildId, oqProfiles] = await Promise.all([
      this._schoolYearConfigurationStore.getParentsByChildId(this.configId, false),
      this._metricsStore.getAccountOQMetrics(this.configId, studentIds, false)
    ]);

    const oqProfilesByStudentId = new Map(oqProfiles.map((p) => [p.accountId, p]));

    const accounts = _.chain(students)
      .map<AccountInfo>((account) => ({
        id: account.id,
        account,
        oqProfile: oqProfilesByStudentId.get(account.id)?.oqProfile,
        invitesParent: getParentInvitationOQValue(account, parentsByChildId)
      }))
      .filter(
        (studentInfo) =>
          (!filters.behaviour.appOpenFilter ||
            filters.behaviour.appOpenFilter === studentInfo.oqProfile?.opensTheApp) &&
          (!filters.behaviour.taskCompletionFilter ||
            filters.behaviour.taskCompletionFilter === studentInfo.oqProfile?.marksTasksAsDone)
      )
      .value();

    return new AppFilteredStudentsBehaviorViewModel(
      this._localizationService,
      this._alertService,
      accounts,
      parentsByChildId
    );
  }
}
