import { AlertService, NavigationService } from '@insights/services';
import { LocalizationService } from '@shared/resources/services';
import { MicrosoftTeamsConnectorStore } from '@shared/services/stores/interfaces';
import { NavigateFunctionAsync } from '@shared/utils';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';

export interface MicrosoftTeamsAuthorizationRedirectViewModel {
  readonly configId: string;

  navigateToExternalAccounts(navigate: NavigateFunctionAsync): Promise<void>;
}

export interface MicrosoftTeamsAuthorizationRedirectScreenViewModel {
  readonly configId: string;
  readonly externalAccountId: string;

  readonly data: IPromiseBasedObservable<MicrosoftTeamsAuthorizationRedirectViewModel>;
}

export class AppMicrosoftTeamsAuthorizationRedirectViewModel implements MicrosoftTeamsAuthorizationRedirectViewModel {
  constructor(
    private readonly _navigationService: NavigationService,
    readonly configId: string
  ) {}

  async navigateToExternalAccounts(navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToExternalAccounts(this.configId, navigate);
  }
}

export class AppMicrosoftTeamsAuthorizationRedirectScreenViewModel
  implements MicrosoftTeamsAuthorizationRedirectScreenViewModel
{
  constructor(
    private readonly _teamsStore: MicrosoftTeamsConnectorStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService,
    private readonly _state: string,
    private readonly _tenantId: string
  ) {
    makeObservable(this);
  }

  @computed
  private get stateParts(): string[] {
    return this._state.split(',');
  }

  @computed
  get configId() {
    return this.stateParts[0] ?? '';
  }

  @computed
  get externalAccountId() {
    return this.stateParts[1] ?? '';
  }

  get data(): IPromiseBasedObservable<MicrosoftTeamsAuthorizationRedirectViewModel> {
    return fromPromise(this.loadData());
  }

  private async loadData(): Promise<MicrosoftTeamsAuthorizationRedirectViewModel> {
    if (this.configId.length === 0 || this._tenantId.length === 0) {
      throw new Error('Invalid parameter');
    }

    await this._teamsStore.createOrUpdateTeamsAccount(
      this.configId,
      this.externalAccountId,
      'Microsoft Teams',
      this._tenantId
    );

    return new AppMicrosoftTeamsAuthorizationRedirectViewModel(this._navigationService, this.configId);
  }
}
