import { ImportDataDetails, ObservablePresenter, PageHeaderBar } from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { useParams } from 'react-router';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface ImportDataDetailsScreenProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const ImportDataDetailsScreen = observer((props: ImportDataDetailsScreenProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights.views.imports;

  const params = useParams();
  const configId = params.configId ?? '';
  const sessionId = params.sessionId ?? '';
  const label = params.label ?? '';
  const viewModel = useMemo(
    () => viewModelFactory.createImportDataDetails(configId, sessionId, label),
    [configId, sessionId, label]
  );

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.details}
          loadingMessage={strings.loadingImportDataMessage}
          errorMessage={strings.importDataErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <ImportDataDetails viewModel={data} />
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
