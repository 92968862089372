import {
  SchoolYearConfiguration_AssessmentPlanningSettings as PBAssessmentPlanningSettings,
  SchoolYearConfiguration_ConfigType as PBConfigType,
  SchoolYearConfiguration_CycleDayKind as PBCycleDayKind,
  SchoolYearConfiguration_DayConfiguration as PBDayConfiguration,
  SchoolYearConfiguration_Link as PBLink,
  SchoolYearConfiguration_Schedule as PBSchedule,
  SchoolYearConfiguration_ScheduleKind as PBScheduleKind,
  SchoolYearConfiguration as PBSchoolYearConfiguration,
  Section as PBSection,
  SchoolYearConfiguration_SpecialDay as PBSpecialDay,
  SchoolYearConfiguration_Term as PBTerm,
  SchoolYearConfiguration_WorkloadThreshold as PBWorkloadThreshold
} from '@buf/studyo_studyo.bufbuild_es/studyo/type_config_pb';
import { dateService } from '@shared/services/DateService';
import _ from 'lodash';
import { action, computed, makeObservable } from 'mobx';
import {
  EditableDayPropertyEx,
  EditableListPropertyEx,
  EditableModelEx,
  EditableNullableDatePropertyEx,
  EditableNullableDayPropertyEx,
  EditableStringArrayProperty,
  EditableStringProperty,
  EditableValueArrayPropertyEx,
  EditableValuePropertyEx,
  FullyEditableListProperty
} from '../editables';
import { ConfigState, CycleDayKind, Day, GradeLevelSource, Integration, PremiumFeature, ScheduleKind } from '../types';
import {
  protobufFromConfigState,
  protobufFromCycleDayKind,
  protobufFromDate,
  protobufFromGradeLevelSource,
  protobufFromIntegration,
  protobufFromPremiumFeature,
  protobufFromScheduleKind
} from '../types/EnumConversion';
import { AssessmentPlanningSettingsModel } from './AssessmentPlanningSettings';
import { DayConfigurationModel } from './DayConfiguration';
import { EditableAssessmentPlanningSettings } from './EditableAssessmentPlanningSettings';
import { EditableDayConfiguration } from './EditableDayConfiguration';
import { EditableLink } from './EditableLink';
import { EditableSchedule } from './EditableSchedule';
import { EditableSection } from './EditableSection';
import { EditableSpecialDay } from './EditableSpecialDay';
import { EditableTerm } from './EditableTerm';
import { EditableWorkloadThreshold } from './EditableWorkloadThreshold';
import { LinkModel } from './Link';
import { ScheduleModel } from './Schedule';
import { SchoolYearConfiguration, SchoolYearConfigurationModel } from './SchoolYearConfiguration';
import { SectionModel } from './Section';
import { SpecialDayModel } from './SpecialDay';
import { TermModel } from './Term';
import { WorkloadThresholdModel } from './WorkloadThreshold';

export class EditableSchoolYearConfiguration
  extends EditableModelEx<PBSchoolYearConfiguration>
  implements SchoolYearConfigurationModel
{
  private _version: EditableValuePropertyEx<number, PBSchoolYearConfiguration>;
  private _schoolName: EditableStringProperty<PBSchoolYearConfiguration>;
  private _title: EditableStringProperty<PBSchoolYearConfiguration>;
  private _daysPerCycle: EditableValuePropertyEx<number, PBSchoolYearConfiguration>;
  private _startDay: EditableDayPropertyEx<PBSchoolYearConfiguration>;
  private _endDay: EditableDayPropertyEx<PBSchoolYearConfiguration>;
  private _managedOnboardCode: EditableStringProperty<PBSchoolYearConfiguration>;
  private _onboardingCodeEmailDomain: EditableStringProperty<PBSchoolYearConfiguration>;
  private _language: EditableStringProperty<PBSchoolYearConfiguration>;
  private _timezone: EditableStringProperty<PBSchoolYearConfiguration>;
  private _enabledIntegrations: EditableValueArrayPropertyEx<Integration, PBSchoolYearConfiguration>;
  private _isManagedWithSubscriptions: EditableValuePropertyEx<boolean, PBSchoolYearConfiguration>;
  private _managedSubscriptionCoupon: EditableStringProperty<PBSchoolYearConfiguration>;
  private _comments: EditableValuePropertyEx<string, PBSchoolYearConfiguration>;
  private _scheduleKind: EditableValuePropertyEx<ScheduleKind, PBSchoolYearConfiguration>;
  private _cycleDayKind: EditableValuePropertyEx<CycleDayKind, PBSchoolYearConfiguration>;
  private _cycleDayTitles: EditableValueArrayPropertyEx<string, PBSchoolYearConfiguration>;
  private _schedules: EditableListPropertyEx<PBSchedule, ScheduleModel, EditableSchedule, PBSchoolYearConfiguration>;
  private _specialDays: EditableListPropertyEx<
    PBSpecialDay,
    SpecialDayModel,
    EditableSpecialDay,
    PBSchoolYearConfiguration
  >;
  private _dayConfigurations: EditableListPropertyEx<
    PBDayConfiguration,
    DayConfigurationModel,
    EditableDayConfiguration,
    PBSchoolYearConfiguration
  >;
  private _links: FullyEditableListProperty<PBLink, LinkModel, EditableLink, PBSchoolYearConfiguration>;
  private _terms: EditableListPropertyEx<PBTerm, TermModel, EditableTerm, PBSchoolYearConfiguration>;
  private _sections: EditableListPropertyEx<PBSection, SectionModel, EditableSection, PBSchoolYearConfiguration>;
  private _teacherAvailabilityDate: EditableNullableDatePropertyEx<PBSchoolYearConfiguration>;
  private _studentAvailabilityDate: EditableNullableDatePropertyEx<PBSchoolYearConfiguration>;
  private _isDemo: EditableValuePropertyEx<boolean, PBSchoolYearConfiguration>;
  private _demoDay: EditableNullableDayPropertyEx<PBSchoolYearConfiguration>;
  private _dailyWorkloadThreshold: EditableValuePropertyEx<number, PBSchoolYearConfiguration>;
  private _weeklyWorkloadThreshold: EditableValuePropertyEx<number, PBSchoolYearConfiguration>;
  private _workloadThresholds: FullyEditableListProperty<
    PBWorkloadThreshold,
    WorkloadThresholdModel,
    EditableWorkloadThreshold,
    PBSchoolYearConfiguration
  >;
  private _assessmentPlanningSettings: FullyEditableListProperty<
    PBAssessmentPlanningSettings,
    AssessmentPlanningSettingsModel,
    EditableAssessmentPlanningSettings,
    PBSchoolYearConfiguration
  >;
  private _disabledFeatures: EditableValueArrayPropertyEx<PremiumFeature, PBSchoolYearConfiguration>;
  private _gradeLevelSource: EditableValuePropertyEx<GradeLevelSource, PBSchoolYearConfiguration>;
  private _state: EditableValuePropertyEx<ConfigState, PBSchoolYearConfiguration>;
  private _tags: EditableStringArrayProperty<PBSchoolYearConfiguration>;
  private _allowedScheduleTags: EditableStringArrayProperty<PBSchoolYearConfiguration>;
  private _expectedStudentCount: EditableValuePropertyEx<number, PBSchoolYearConfiguration>;
  private _previousConfigurationId: EditableStringProperty<PBSchoolYearConfiguration>;

  private _specialDaysByDeletedSchedule: Map<string, string[]>;

  static createNew() {
    // Last Monday of August.
    const startDay = Day.create({
      day: 31,
      month: 8,
      year: dateService.today.year
    }).firstDayOfWeek('monday');
    // Last Friday of next June.
    const endDay = Day.create({
      day: 30,
      month: 6,
      year: startDay.year + 1
    }).firstDayOfWeek('friday');

    const pb = new PBSchoolYearConfiguration();
    pb.startDay = startDay.asPB;
    pb.endDay = endDay.asPB;
    pb.daysPerCycle = 6;
    pb.language = 'en';

    pb.dailyWorkloadThreshold = 2;
    pb.weeklyWorkloadThreshold = 3;
    pb.cycleDayKind = PBCycleDayKind.NUMBERS;
    pb.scheduleKind = PBScheduleKind.CYCLE_DAYS;
    pb.type = PBConfigType.MANAGED;
    pb.version = 4;

    return new EditableSchoolYearConfiguration(new SchoolYearConfiguration(pb), true);
  }

  static createNewFrom(config: SchoolYearConfigurationModel) {
    // Last Monday of August.
    const startDay = Day.create({
      day: 31,
      month: 8,
      year: dateService.today.year
    }).firstDayOfWeek('monday');
    // Last Friday of next June.
    const endDay = Day.create({
      day: 30,
      month: 6,
      year: startDay.year + 1
    }).firstDayOfWeek('friday');

    const pb = new PBSchoolYearConfiguration();
    // The title is not kept, defaults to years.
    pb.schoolName = config.schoolName;

    // The start and end dates are always new
    pb.startDay = startDay.asPB;
    pb.endDay = endDay.asPB;
    pb.daysPerCycle = config.daysPerCycle;
    pb.language = config.language;

    pb.dailyWorkloadThreshold = config.dailyWorkloadThreshold;
    pb.weeklyWorkloadThreshold = config.weeklyWorkloadThreshold;

    // Though these should be the same as above, we use the source.
    pb.cycleDayKind = protobufFromCycleDayKind(config.cycleDayKind);
    pb.scheduleKind = protobufFromScheduleKind(config.scheduleKind);
    // We never create individual school copies.
    pb.type = PBConfigType.MANAGED;
    pb.version = 4;

    // The rest is not kept.
    return new EditableSchoolYearConfiguration(new SchoolYearConfiguration(pb), true);
  }

  constructor(
    private readonly _originalConfig: SchoolYearConfigurationModel,
    isNew = false
  ) {
    super(_originalConfig.toProtobuf(), isNew);
    makeObservable(this);

    this.setFields([
      (this._version = new EditableValuePropertyEx(_originalConfig.version, (pb, value) => (pb.version = value))),
      (this._schoolName = new EditableStringProperty(
        _originalConfig.schoolName,
        (pb, value) => (pb.schoolName = value),
        {
          trim: true
        }
      )),
      (this._title = new EditableStringProperty(_originalConfig.title, (pb, value) => (pb.title = value), {
        trim: true
      })),
      (this._daysPerCycle = new EditableValuePropertyEx(
        _originalConfig.daysPerCycle,
        (pb, value) => (pb.daysPerCycle = value)
      )),
      (this._startDay = new EditableDayPropertyEx(_originalConfig.startDay, (pb, value) => (pb.startDay = value.asPB))),
      (this._endDay = new EditableDayPropertyEx(_originalConfig.endDay, (pb, value) => (pb.endDay = value.asPB))),
      (this._managedOnboardCode = new EditableStringProperty(
        _originalConfig.managedOnboardCode,
        (pb, value) => (pb.managedOnboardCode = value),
        {
          trim: true
        }
      )),
      (this._onboardingCodeEmailDomain = new EditableStringProperty(
        _originalConfig.onboardingCodeEmailDomain,
        (pb, value) => (pb.onboardingCodeEmailDomain = value),
        {
          trim: true
        }
      )),
      (this._language = new EditableStringProperty(_originalConfig.language, (pb, value) => (pb.language = value), {
        trim: true
      })),
      (this._timezone = new EditableStringProperty(_originalConfig.timezone, (pb, value) => (pb.timezone = value), {
        trim: true
      })),
      (this._enabledIntegrations = new EditableValueArrayPropertyEx(
        _originalConfig.enabledIntegrations,
        (pb, values) => (pb.enabledIntegrations = values.map(protobufFromIntegration))
      )),
      (this._isManagedWithSubscriptions = new EditableValuePropertyEx(
        _originalConfig.isManagedWithSubscriptions,
        (pb, value) => (pb.isManagedWithSubscriptions = value)
      )),
      (this._managedSubscriptionCoupon = new EditableStringProperty(
        _originalConfig.managedSubscriptionCoupon,
        (pb, value) => (pb.managedSubscriptionCoupon = value),
        {
          trim: true
        }
      )),
      (this._comments = new EditableValuePropertyEx(_originalConfig.comments, (pb, value) => (pb.comments = value))),
      (this._scheduleKind = new EditableValuePropertyEx(
        _originalConfig.scheduleKind,
        (pb, value) => (pb.scheduleKind = protobufFromScheduleKind(value))
      )),
      (this._cycleDayKind = new EditableValuePropertyEx(
        _originalConfig.cycleDayKind,
        (pb, value) => (pb.cycleDayKind = protobufFromCycleDayKind(value))
      )),
      (this._cycleDayTitles = new EditableValueArrayPropertyEx(
        _originalConfig.cycleDayTitles,
        (pb, values) => (pb.cycleDayTitles = values)
      )),
      (this._schedules = new EditableListPropertyEx(
        _originalConfig.schedules,
        (model, isNew) => new EditableSchedule(model, isNew),
        (pb, values) => (pb.schedules = values)
      )),
      (this._specialDays = new EditableListPropertyEx(
        _originalConfig.specialDays,
        (model, isNew) => new EditableSpecialDay(model, isNew),
        (pb, values) => (pb.specialDays = values)
      )),
      (this._dayConfigurations = new EditableListPropertyEx(
        _originalConfig.dayConfigurations,
        (model, isNew) => new EditableDayConfiguration(model, isNew),
        (pb, values) => (pb.dayConfigurations = values)
      )),
      (this._links = new FullyEditableListProperty(
        _originalConfig.links.map((link) => new EditableLink(link)),
        (pb, values) => (pb.links = values)
      )),
      (this._terms = new EditableListPropertyEx(
        _originalConfig.terms,
        (model, isNew) => new EditableTerm(model, isNew),
        (pb, values) => (pb.terms = values)
      )),
      (this._sections = new EditableListPropertyEx(
        _originalConfig.sections,
        (model, isNew) => new EditableSection(model, isNew),
        (pb, values) => (pb.sections = values)
      )),
      (this._teacherAvailabilityDate = new EditableNullableDatePropertyEx<PBSchoolYearConfiguration>(
        _originalConfig.teacherAvailabilityDate,
        (pb, value) => {
          if (value == null) {
            pb.teacherAvailabilityDate = undefined;
          } else {
            pb.teacherAvailabilityDate = protobufFromDate(value);
          }
        }
      )),
      (this._studentAvailabilityDate = new EditableNullableDatePropertyEx<PBSchoolYearConfiguration>(
        _originalConfig.studentAvailabilityDate,
        (pb, value) => {
          if (value == null) {
            pb.studentAvailabilityDate = undefined;
          } else {
            pb.studentAvailabilityDate = protobufFromDate(value);
          }
        }
      )),
      (this._isDemo = new EditableValuePropertyEx(_originalConfig.isDemo, (pb, value) => (pb.isDemo = value))),
      (this._demoDay = new EditableNullableDayPropertyEx(
        _originalConfig.demoDay,
        (pb, value) => (pb.demoDay = value?.asPB)
      )),
      (this._dailyWorkloadThreshold = new EditableValuePropertyEx(
        _originalConfig.dailyWorkloadThreshold,
        (pb, value) => (pb.dailyWorkloadThreshold = value)
      )),
      (this._weeklyWorkloadThreshold = new EditableValuePropertyEx(
        _originalConfig.weeklyWorkloadThreshold,
        (pb, value) => (pb.weeklyWorkloadThreshold = value)
      )),
      (this._workloadThresholds = new FullyEditableListProperty(
        _originalConfig.workloadThresholds.map((threshold) => new EditableWorkloadThreshold(threshold)),
        (pb, values) => (pb.workloadThresholds = values)
      )),
      (this._assessmentPlanningSettings = new FullyEditableListProperty(
        _originalConfig.assessmentPlanningSettings.map(
          (assessmentPlanningSettings) => new EditableAssessmentPlanningSettings(assessmentPlanningSettings)
        ),
        (pb, values) => (pb.assessmentPlanningSettings = values)
      )),
      (this._disabledFeatures = new EditableValueArrayPropertyEx(
        _originalConfig.disabledFeatures,
        (pb, values) => (pb.disabledFeatures = values.map((v) => protobufFromPremiumFeature(v)))
      )),
      (this._gradeLevelSource = new EditableValuePropertyEx(
        _originalConfig.gradeLevelSource,
        (pb, value) => (pb.gradeLevelSource = protobufFromGradeLevelSource(value))
      )),
      (this._state = new EditableValuePropertyEx(
        _originalConfig.state,
        (pb, value) => (pb.state = protobufFromConfigState(value))
      )),
      (this._tags = new EditableStringArrayProperty(_originalConfig.tags, (pb, values) => (pb.tags = values), {
        trim: true
      })),
      (this._allowedScheduleTags = new EditableStringArrayProperty(
        _originalConfig.allowedScheduleTags,
        (pb, values) => (pb.allowedScheduleTags = values),
        {
          trim: true
        }
      )),
      (this._expectedStudentCount = new EditableValuePropertyEx(
        _originalConfig.expectedStudentCount,
        (pb, value) => (pb.expectedStudentCount = value)
      )),
      (this._previousConfigurationId = new EditableStringProperty(
        _originalConfig.previousConfigurationId,
        (pb, value) => (pb.previousConfigurationId = value),
        { trim: true }
      ))
    ]);

    this._specialDaysByDeletedSchedule = new Map<string, string[]>();
  }

  //
  // Read-only properties
  //

  get id() {
    return this._originalConfig.id;
  }

  get syncToken() {
    return this._originalConfig.syncToken;
  }

  get isDeleted() {
    return this._originalConfig.isDeleted;
  }

  get type() {
    return this._originalConfig.type;
  }

  //
  // Editable properties
  //

  @computed
  get version() {
    return this._version.value;
  }

  set version(value: number) {
    this._version.value = value;
  }

  @computed
  get schoolName() {
    return this._schoolName.value;
  }

  set schoolName(value: string) {
    this._schoolName.value = value;
  }

  @computed
  get title() {
    return this._title.value;
  }

  set title(value: string) {
    this._title.value = value;
  }

  @computed
  get daysPerCycle() {
    return this._daysPerCycle.value;
  }

  set daysPerCycle(value: number) {
    this._daysPerCycle.value = value;

    // We must make sure the cycle day labels have the same number of items.
    // It makes that validation when set.
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.cycleDayTitles;

    // Deleting day configuration for cycle days that don't exist.
    this._dayConfigurations.editValidValues(
      (dc) => dc.cycleDay != null,
      (edc) => {
        if (edc.cycleDay! > this.daysPerCycle) {
          edc.markAsDeleted();
        } else if (edc.shouldBeDeleted) {
          edc.markAsNotDeleted();
        }
      },
      true
    );
  }

  @computed
  get startDay() {
    return this._startDay.value;
  }

  set startDay(value: Day) {
    this._startDay.value = value;
  }

  @computed
  get endDay() {
    return this._endDay.value;
  }

  set endDay(value: Day) {
    this._endDay.value = value;
  }

  @computed
  get managedOnboardCode() {
    return this._managedOnboardCode.value;
  }

  set managedOnboardCode(value: string) {
    this._managedOnboardCode.value = value;
  }

  @computed
  get onboardingCodeEmailDomain(): string {
    return this._onboardingCodeEmailDomain.value;
  }

  set onboardingCodeEmailDomain(value: string) {
    this._onboardingCodeEmailDomain.value = value;
  }

  @computed
  get language() {
    return this._language.value;
  }

  set language(value: string) {
    this._language.value = value;
  }

  @computed
  get timezone() {
    return this._timezone.value;
  }

  set timezone(value: string) {
    this._timezone.value = value;
  }

  @computed
  get enabledIntegrations() {
    return this._enabledIntegrations.value;
  }

  set enabledIntegrations(values: Integration[]) {
    this._enabledIntegrations.value = values;
  }

  @computed
  get isManagedWithSubscriptions() {
    return this._isManagedWithSubscriptions.value;
  }

  set isManagedWithSubscriptions(value: boolean) {
    this._isManagedWithSubscriptions.value = value;
  }

  @computed
  get managedSubscriptionCoupon() {
    return this._managedSubscriptionCoupon.value;
  }

  set managedSubscriptionCoupon(value: string) {
    this._managedSubscriptionCoupon.value = value;
  }

  @computed
  get comments() {
    return this._comments.value;
  }

  set comments(value: string) {
    this._comments.value = value;
  }

  @computed
  get isDemo() {
    return this._isDemo.value;
  }

  set isDemo(value: boolean) {
    this._isDemo.value = value;
  }

  @computed
  get demoDay() {
    return this._demoDay.value;
  }

  set demoDay(value: Day | undefined) {
    this._demoDay.value = value;
  }

  @computed
  get scheduleKind() {
    return this._scheduleKind.value;
  }

  set scheduleKind(value: ScheduleKind) {
    this._scheduleKind.value = value;
  }

  @computed
  get cycleDayKind() {
    return this._cycleDayKind.value;
  }

  set cycleDayKind(value: CycleDayKind) {
    this._cycleDayKind.value = value;
  }

  @computed
  get cycleDayTitles() {
    // Arrays are ref types. We must not return the original.
    // TODO: I hate this, should be handled by EditableValueArrayProperty or something.
    //       Maybe should expose observable.array in first place?
    const titles = this._cycleDayTitles.value.slice();

    // Source data may have wrong number of entries compared to number of cycle days. We act as if not.
    if (titles.length < this.daysPerCycle) {
      return titles.concat(new Array(this.daysPerCycle - titles.length).fill(''));
    } else if (titles.length > this.daysPerCycle) {
      return titles.slice(0, this.daysPerCycle);
    }

    return titles;
  }

  set cycleDayTitles(values: string[]) {
    if (values.length != this.daysPerCycle) {
      // The setter is always safe, and either pads or shrinks. It's also used internally when daysPerCycle changes.
      if (values.length < this.daysPerCycle) {
        values = values.concat(new Array(this.daysPerCycle - values.length).fill(''));
      } else {
        values = values.slice(0, this.daysPerCycle);
      }
    }

    this._cycleDayTitles.value = values;
  }

  @computed
  get state() {
    return this._state.value;
  }

  set state(value: ConfigState) {
    this._state.value = value;
  }

  @computed
  get tags(): string[] {
    return this._tags.value;
  }

  set tags(values: string[]) {
    this._tags.value = values;
  }

  @computed
  get allowedScheduleTags(): string[] {
    return this._allowedScheduleTags.value;
  }

  set allowedScheduleTags(values: string[]) {
    this._allowedScheduleTags.value = values;
  }

  @computed
  get expectedStudentCount() {
    return this._expectedStudentCount.value;
  }

  set expectedStudentCount(value: number) {
    this._expectedStudentCount.value = value;
  }

  @computed
  get previousConfigurationId() {
    return this._previousConfigurationId.value;
  }

  set previousConfigurationId(value: string) {
    this._previousConfigurationId.value = value;
  }

  addAllowedScheduleTag(tag: string) {
    this.allowedScheduleTags = _.chain([...this.allowedScheduleTags, tag])
      .uniq()
      .value();
  }

  removeAllowedScheduleTag(tag: string) {
    this.allowedScheduleTags = _.chain(this.allowedScheduleTags)
      .filter((t) => t !== tag)
      .uniq()
      .value();
  }

  /*
   * Schedules
   */

  @computed
  get schedules(): ScheduleModel[] {
    return this._schedules.values;
  }

  @computed
  get allSchedules(): ScheduleModel[] {
    return this._schedules.allValues;
  }

  @computed
  get deletedSchedules(): ScheduleModel[] {
    return this._schedules.deletedValues;
  }

  getIsScheduleDeleted(schedule: ScheduleModel): boolean {
    return this._schedules.itemShouldBeDeleted(schedule);
  }

  getIsScheduleChanged(schedule: ScheduleModel): boolean {
    return this._schedules.itemIsChanged(schedule);
  }

  @action
  getEditableSchedule(schedule: ScheduleModel): EditableSchedule {
    return this._schedules.getEditableValue(schedule);
  }

  @action
  editSchedules(filter: (s: ScheduleModel) => boolean, editAction: (es: EditableSchedule) => void) {
    this._schedules.editValidValues(filter, editAction);
  }

  @action
  editAllSchedules(editAction: (es: EditableSchedule) => void) {
    this._schedules.editAllValidValues(editAction);
  }

  @action
  addSchedule(schedule: EditableSchedule): void {
    this._schedules.addItem(schedule);
  }

  @action
  deleteSchedule(schedule: ScheduleModel): void {
    // Simply marking the schedule as deleted is enough by itself, but we also need to delete references.
    this._dayConfigurations.editValidValues(
      (dc) => dc.scheduleId === schedule.id,
      (edc) => edc.markAsDeleted()
    );

    // It's not necessary to find schedule ids and remove by index. Setting the same array does not trigger useless change.
    const specialDayIds: string[] = [];
    this._specialDays.editValidValues(
      (sd) => sd.scheduleIds.includes(schedule.id),
      (sd) => {
        specialDayIds.push(sd.id);
        return (sd.scheduleIds = sd.scheduleIds.filter((id) => id !== schedule.id));
      },
      true
    );

    // Storing the deleted schedules special days to be able to undelete them
    this._specialDaysByDeletedSchedule.set(schedule.id, specialDayIds);

    this._schedules.getEditableValue(schedule).markAsDeleted();
  }

  @action
  undeleteSchedule(schedule: ScheduleModel): void {
    // Simply marking the schedule as not deleted is enough by itself, but we also need to undelete references.
    this._dayConfigurations.editValidValues(
      (dc) => dc.scheduleId === schedule.id,
      (edc) => edc.markAsNotDeleted(),
      true
    );

    // Add the schedule to all special days where it was deleted from
    const specialDayIds = this._specialDaysByDeletedSchedule.get(schedule.id) ?? [];
    this._specialDays.editValidValues(
      (sd) => specialDayIds.includes(sd.id),
      (sd) => (sd.scheduleIds = sd.scheduleIds.concat(schedule.id)),
      true
    );

    this._schedules.getEditableValue(schedule).markAsNotDeleted();
  }

  /*
   * Special days
   */

  @computed
  get specialDays(): SpecialDayModel[] {
    return this._specialDays.values;
  }

  @computed
  get allSpecialDays(): SpecialDayModel[] {
    return this._specialDays.allValues;
  }

  getIsSpecialDayDeleted(specialDay: SpecialDayModel): boolean {
    return this._specialDays.itemShouldBeDeleted(specialDay);
  }

  getIsSpecialDayChanged(specialDay: SpecialDayModel): boolean {
    return this._specialDays.itemIsChanged(specialDay);
  }

  @action
  addSpecialDay(specialDay: EditableSpecialDay): void {
    this._specialDays.addItem(specialDay);
  }

  @action
  deleteSpecialDay(specialDay: SpecialDayModel): void {
    // Simply marking the specialDay as deleted is enough by itself, but we also need to delete referencing dayConfigurations.
    this._dayConfigurations.editValidValues(
      (dc) => dc.specialDayId === specialDay.id,
      (dc) => dc.markAsDeleted()
    );
    this._specialDays.getEditableValue(specialDay).markAsDeleted();
  }

  @action
  undeleteSpecialDay(specialDay: SpecialDayModel): void {
    /*
     * Simply marking the specialDay as not deleted is enough by itself,
     * but we also need to undelete referencing dayConfigurations.
     */
    this._dayConfigurations.editValidValues(
      (dc) => dc.specialDayId === specialDay.id,
      (dc) => dc.markAsNotDeleted(),
      true
    );
    this._specialDays.getEditableValue(specialDay).markAsNotDeleted();
  }

  @action
  getEditableSpecialDay(specialDay: SpecialDayModel): EditableSpecialDay {
    return this._specialDays.getEditableValue(specialDay);
  }

  @action
  editSpecialDays(filter: (sd: SpecialDayModel) => boolean, editAction: (esd: EditableSpecialDay) => void) {
    this._specialDays.editValidValues(filter, editAction);
  }

  @action
  editAllSpecialDays(editAction: (esd: EditableSpecialDay) => void) {
    this._specialDays.editAllValidValues(editAction);
  }

  /*
   * Day configurations
   */

  @computed
  get dayConfigurations(): DayConfigurationModel[] {
    return this._dayConfigurations.values;
  }

  @computed
  get allDayConfigurations(): DayConfigurationModel[] {
    return this._dayConfigurations.allValues;
  }

  @action
  addDayConfiguration(dayConfiguration: EditableDayConfiguration): void {
    this._dayConfigurations.addItem(dayConfiguration);
  }

  @action
  getEditableDayConfiguration(dayConfiguration: DayConfigurationModel): EditableDayConfiguration {
    return this._dayConfigurations.getEditableValue(dayConfiguration);
  }

  @action
  editDayConfigurations(
    filter: (dc: DayConfigurationModel) => boolean,
    editAction: (edc: EditableDayConfiguration) => void
  ) {
    this._dayConfigurations.editValidValues(filter, editAction);
  }

  @action
  editAllDayConfigurations(editAction: (edc: EditableDayConfiguration) => void) {
    this._dayConfigurations.editAllValidValues(editAction);
  }

  /*
   * Links
   */

  @computed
  get links() {
    return this._links.values;
  }

  @computed
  get editableLinks(): EditableLink[] {
    return this._links.allValues;
  }

  @action
  addLink(link: EditableLink): void {
    this._links.addItem(link);
  }

  @action
  sortLinks(): void {
    this._links.sortBy((l) => l.sortPosition);
  }

  /*
   * Terms
   */

  @computed
  get terms() {
    return this._terms.values;
  }

  @computed
  get allTerms() {
    return this._terms.allValues;
  }

  getIsTermDeleted(term: TermModel): boolean {
    return this._terms.itemShouldBeDeleted(term);
  }

  getIsTermChanged(term: TermModel): boolean {
    return this._terms.itemIsChanged(term);
  }

  @action
  addTerm(term: EditableTerm): void {
    this._terms.addItem(term);
  }

  @action
  deleteTerm(term: TermModel): void {
    this._terms.getEditableValue(term).markAsDeleted();
  }

  @action
  undeleteTerm(term: TermModel): void {
    this._terms.getEditableValue(term).markAsNotDeleted();
  }

  @action
  getEditableTerm(term: TermModel): EditableTerm {
    return this._terms.getEditableValue(term);
  }

  @action
  editTerms(filter: (term: TermModel) => boolean, editAction: (et: EditableTerm) => void) {
    this._terms.editValidValues(filter, editAction);
  }

  @action
  editAllTerms(editAction: (et: EditableTerm) => void) {
    this._terms.editAllValidValues(editAction);
  }

  /*
   * Sections
   */

  @computed
  get sections(): SectionModel[] {
    return this._sections.values;
  }

  @action
  getEditableSection(section?: SectionModel): EditableSection | undefined {
    if (section == null) {
      return undefined;
    }

    return this._sections.getEditableValue(section);
  }

  addSection(section: EditableSection) {
    if (this._sections == null) {
      throw new Error('This EditableSchoolYearConfiguration was created with section edition disabled.');
    }

    this._sections.addItem(section);
  }

  @computed
  get dailyWorkloadThreshold(): number {
    return this._dailyWorkloadThreshold.value;
  }

  set dailyWorkloadThreshold(value: number) {
    this._dailyWorkloadThreshold.value = value;
  }

  @computed
  get weeklyWorkloadThreshold(): number {
    return this._weeklyWorkloadThreshold.value;
  }

  set weeklyWorkloadThreshold(value: number) {
    this._weeklyWorkloadThreshold.value = value;
  }

  /*
   * Workload Thresholds
   */

  @computed
  get workloadThresholds(): EditableWorkloadThreshold[] {
    return this._workloadThresholds.values;
  }

  get allWorkloadThresholds(): EditableWorkloadThreshold[] {
    return this._workloadThresholds.allValues;
  }

  @action
  addWorkloadThreshold(workloadThreshold: EditableWorkloadThreshold) {
    this._workloadThresholds.addItem(workloadThreshold);
  }

  /*
   * Assessment Planning Settings
   */

  @computed
  get assessmentPlanningSettings(): EditableAssessmentPlanningSettings[] {
    return this._assessmentPlanningSettings.values;
  }

  get allAssessmentPlanningSettings(): EditableAssessmentPlanningSettings[] {
    return this._assessmentPlanningSettings.allValues;
  }

  @action
  addAssessmentPlanningSettings(assessmentPlanningSettings: EditableAssessmentPlanningSettings) {
    this._assessmentPlanningSettings.addItem(assessmentPlanningSettings);
  }

  @computed
  get teacherAvailabilityDate(): Date | undefined {
    return this._teacherAvailabilityDate.value;
  }

  set teacherAvailabilityDate(value: Date | undefined) {
    this._teacherAvailabilityDate.value = value;
  }

  @computed
  get studentAvailabilityDate(): Date | undefined {
    return this._studentAvailabilityDate.value;
  }

  set studentAvailabilityDate(value: Date | undefined) {
    this._studentAvailabilityDate.value = value;
  }

  /*
   * Disabled Features
   */

  @computed
  get disabledFeatures(): PremiumFeature[] {
    return this._disabledFeatures.value;
  }

  set disabledFeatures(value: PremiumFeature[]) {
    this._disabledFeatures.value = value;
  }

  addDisabledFeature(feature: PremiumFeature) {
    this.disabledFeatures = _.chain([...this.disabledFeatures, feature])
      .uniq()
      .value();
  }

  removeDisabledFeature(feature: PremiumFeature) {
    this.disabledFeatures = _.chain(this.disabledFeatures)
      .filter((f) => f !== feature)
      .uniq()
      .value();
  }

  @computed
  get gradeLevelSource(): GradeLevelSource {
    return this._gradeLevelSource.value;
  }

  set gradeLevelSource(value: GradeLevelSource) {
    this._gradeLevelSource.value = value;
  }

  clone() {
    // TODO: Should we have "shallow" clone?
    const pb = this.toProtobuf();
    return new EditableSchoolYearConfiguration(new SchoolYearConfiguration(pb));
  }

  toProtobufWithoutSections(): PBSchoolYearConfiguration {
    const pb = this.toProtobuf();
    pb.sections = [];

    return pb;
  }
}
