import { AccountList, ObservablePresenter, PageHeaderBar, StudentBehaviorAggregation } from '@insights/components';
import { Box, Card, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface StudentsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Students = observer((props: StudentsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.students;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createStudents(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Student List' });
  });

  return (
    <Box sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingStudentsMessage}
          errorMessage={strings.loadingStudentsErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card sx={{ minWidth: MinLayoutWidth }}>
                <Box height="100%" width="100%">
                  {data.opensAppBehavior != null && data.completesTasksBehavior != null && (
                    <StudentBehaviorAggregation
                      opensApp={data.opensAppBehavior}
                      completesTasks={data.completesTasksBehavior}
                      invitesParent={data.invitesParentBehavior}
                      filterViewModel={data}
                      showEmpty
                    />
                  )}

                  <AccountList
                    title={strings.students}
                    accounts={data.visibleStudents}
                    accountType="student"
                    configId={viewModel.configId}
                    exportableViewModel={data}
                  />
                </Box>
              </Card>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
