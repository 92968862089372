import { AlertConfirmationParameters } from '@insights/services';
import { observer } from 'mobx-react-lite';
import { Location, useBlocker } from 'react-router';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { ConfirmationDialog } from '../ConfirmationDialog.tsx';

export interface NavigationPromptProps {
  when: boolean | ((args: { currentLocation: Location; nextLocation: Location }) => boolean);
  confirmationParameters?: AlertConfirmationParameters;
}

/**
 * This component must be used in a Router
 */
export const NavigationPrompt = observer((props: NavigationPromptProps) => {
  const { localizationService } = useInsightsServices();
  const { when, confirmationParameters } = props;
  const strings = localizationService.localizedStrings.insights.viewModels.edit;

  const resolvedConfirmationParameters: AlertConfirmationParameters = confirmationParameters ?? {
    message: strings.pendingChangesMessage,
    okButtonCaption: strings.discardButtonCaption,
    cancelButtonCaption: strings.cancelButtonCaption
  };

  const blocker = useBlocker(when);

  if (blocker.state === 'blocked') {
    return (
      <ConfirmationDialog
        {...resolvedConfirmationParameters}
        onSuccess={() => blocker.proceed()}
        onCancel={() => blocker.reset()}
      />
    );
  }

  return null;
});
