import { BehaviorAggregationViewModel, FilteredStudentsBehaviorViewModel } from '@insights/viewmodels';
import TaskCompletionIcon from '@mui/icons-material/Check';
import ParentsIcon from '@mui/icons-material/Share';
import AppOpenIcon from '@mui/icons-material/Visibility';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties } from 'react';
import { useInsightsServices } from '../UseInsightsServicesHook';
import { BehaviorAggregation } from './BehaviourAggregation';
import { StudentsFilter } from './StudentsFilter';

export interface StudentBehaviorAggregationProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  opensApp: BehaviorAggregationViewModel;
  completesTasks: BehaviorAggregationViewModel;
  invitesParent?: BehaviorAggregationViewModel;
  filterViewModel?: FilteredStudentsBehaviorViewModel;
  showEmpty?: boolean;
}

export const StudentBehaviorAggregation = observer((props: StudentBehaviorAggregationProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], style, opensApp, completesTasks, invitesParent, filterViewModel, showEmpty } = props;
  const behaviorStrings = localizationService.localizedStrings.insights.components.behaviourSummary;

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="row-reverse" alignItems="center">
      {filterViewModel != null && <StudentsFilter sx={{ mr: 1 }} viewModel={filterViewModel} />}
      {invitesParent != null && (
        <BehaviorAggregation
          sx={{ mr: 1 }}
          icon={<ParentsIcon />}
          tooltip={{
            title: behaviorStrings.invitesParentTooltip,
            grey: behaviorStrings.invitesParentGreyTooltip,
            green: behaviorStrings.invitesParentGreenTooltip,
            yellow: behaviorStrings.invitesParentYellowTooltip,
            red: behaviorStrings.invitesParentRedTooltip
          }}
          viewModel={invitesParent}
          showEmpty={showEmpty}
        />
      )}
      <BehaviorAggregation
        sx={{ mr: 1 }}
        icon={<TaskCompletionIcon />}
        tooltip={{
          title: behaviorStrings.taskCompletionTooltip,
          green: behaviorStrings.taskCompletionGreenTooltip,
          yellow: behaviorStrings.taskCompletionYellowTooltip,
          red: behaviorStrings.taskCompletionRedTooltip
        }}
        viewModel={completesTasks}
        showEmpty={showEmpty}
      />
      <BehaviorAggregation
        sx={{ mr: 1 }}
        icon={<AppOpenIcon />}
        tooltip={{
          title: behaviorStrings.appOpenTooltip,
          grey: behaviorStrings.appOpenGreyTooltip,
          green: behaviorStrings.appOpenGreenTooltip,
          yellow: behaviorStrings.appOpenYellowTooltip,
          red: behaviorStrings.appOpenRedTooltip
        }}
        viewModel={opensApp}
        showEmpty={showEmpty}
      />
    </Box>
  );
});
