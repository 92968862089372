import { css } from '@emotion/css';
import {
  AuthorizationRoleCondition,
  InsightsMaterialTable,
  MoreActionMenu,
  ObservablePresenter,
  PageHeaderBar,
  RouterLink,
  Row
} from '@insights/components';
import { CustomFilterUtils, caseInsensitiveAccentInsensitiveCompare } from '@insights/utils';
import { SchoolConfigurationInfo } from '@insights/viewmodels';
import { MTableAction } from '@material-table/core';
import ArchivedIcon from '@mui/icons-material/Archive';
import PreparingIcon from '@mui/icons-material/HourglassEmpty';
import ArrowDown from '@mui/icons-material/TrendingDown';
import ArrowUp from '@mui/icons-material/TrendingUp';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControlLabel,
  ListItemText,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  SxProps,
  Tooltip,
  Typography,
  styled,
  useTheme
} from '@mui/material';
import { green, red } from '@mui/material/colors';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { TintedImage } from '@shared/rxp/tinted-image';
import { useNavigateAsync } from '@shared/utils';
import _, { map, orderBy, range } from 'lodash';
import { observer } from 'mobx-react-lite';
import moize from 'moize';
import { CSSProperties, useMemo, useRef, useState } from 'react';
import { DefaultTablePageSizes, MinLayoutWidth } from '../../Constants';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';

const TableStateKey = 'SchoolConfigurations';

export interface SchoolConfigurationsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SchoolConfigurations = observer(({ sx = [], className, style }: SchoolConfigurationsProps) => {
  const { imageService, localizationService, reactRouterRouteService, viewModelFactory, settingsStore } =
    useInsightsServices();
  const { tablePreferences } = settingsStore;
  const strings = localizationService.localizedStrings.insights.views;
  const connectorStrings = localizationService.localizedStrings.insights.components.connectors;
  const externalImages = imageService.studyoImages.tasks.external;
  const theme = useTheme();

  const navigate = useNavigateAsync();
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfigurations(), []);

  const [showToast, setShowToast] = useState(false);

  function getYears() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    // range: "... up to, but not including, end".
    return orderBy(range(2018, currentYear + 2), (year) => [year], ['desc']);
  }

  const getSearchableFields = useRef(
    moize((s: SchoolConfigurationInfo) => [
      s.schoolConfiguration.schoolName,
      SchoolYearConfigurationUtils.displayTitle(s.schoolConfiguration),
      s.schoolConfiguration.comments,
      ...s.schoolConfiguration.tags
    ])
  );
  const currentSearchText = tablePreferences.getSearchText(TableStateKey) ?? '';

  async function copyIdToClipboard(config: SchoolConfigurationInfo): Promise<void> {
    await navigator.clipboard.writeText(config.schoolConfiguration.id);
    setShowToast(true);
  }

  const tagClassname = css({
    '& .MuiChip-label': {
      fontSize: theme.typography.caption.fontSize
    }
  });

  return (
    <Root sx={sx} display="flex" flexDirection="column" className={className} style={style}>
      <PageHeaderBar>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography>{strings.year}</Typography>

          <Box marginLeft={1}>
            <Select
              className={'yearSelection'}
              value={viewModel.schoolConfigurationsYear}
              onChange={(e) => (viewModel.schoolConfigurationsYear = Number(e.target.value))}
            >
              {map(getYears(), (year) => (
                <MenuItem key={year} value={year}>
                  {year}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <AuthorizationRoleCondition allowedRoles={['super-admin']}>
            <Box marginLeft={1}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={viewModel.showDemoSchools}
                    onChange={(event) => (viewModel.showDemoSchools = event.target.checked)}
                  />
                }
                label={strings.showDemoSchools}
              />
            </Box>
          </AuthorizationRoleCondition>
        </Box>

        <Box display="flex" flexDirection="row" alignItems="center" className={'actionsRow'}>
          <Button onClick={() => void viewModel.createNewSchool(navigate)}>
            <Typography>{strings.newSchool}</Typography>
          </Button>
        </Box>
      </PageHeaderBar>

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSchoolConfigMessage}
          errorMessage={strings.loadingSchoolConfigErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card className={'card'}>
                <Box height="100%" width="100%">
                  <InsightsMaterialTable
                    stateKey={TableStateKey}
                    // This is to disable the card contour
                    components={{
                      Action: (p) => {
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (!p.action.isFreeAction) {
                          // TODO: configId parameter was removed from isAllowed. Fine elsewhere, but here it was required!
                          //       Otherwise a local admin somewhere could navigate to manage any school. For now, since
                          //       this page is only seen by root admins, the manage and copy id menus are only for them,
                          //       in case this is ever used elsewhere.

                          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                          const data = p.data as SchoolConfigurationInfo;
                          return (
                            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
                              <MoreActionMenu>
                                <MenuItem
                                  onClick={() => void viewModel.editSchool(data.schoolConfiguration.id, navigate)}
                                >
                                  <ListItemText primary={strings.manageThisSchool} />
                                </MenuItem>
                                <MenuItem onClick={() => void copyIdToClipboard(data)}>
                                  <ListItemText primary={strings.copyId} />
                                </MenuItem>
                              </MoreActionMenu>
                            </AuthorizationRoleCondition>
                          );
                        }

                        return <MTableAction {...p} />;
                      }
                    }}
                    title={strings.configurationsTitle}
                    columns={[
                      {
                        title: strings.school,
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) => {
                          return caseInsensitiveAccentInsensitiveCompare(
                            a.schoolConfiguration.schoolName,
                            b.schoolConfiguration.schoolName
                          );
                        },
                        customFilterAndSearch: (filter: string, config: SchoolConfigurationInfo) =>
                          CustomFilterUtils.customFilterAndSearch(filter, config, getSearchableFields.current),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <Stack>
                              <Stack direction="row" alignItems="center" spacing={1}>
                                {rowData.schoolConfiguration.state !== 'active' && (
                                  <Tooltip
                                    title={strings.managedGeneral.getConfigStateLabel(
                                      rowData.schoolConfiguration.state
                                    )}
                                  >
                                    {rowData.schoolConfiguration.state === 'archived' ? (
                                      <ArchivedIcon fontSize="small" />
                                    ) : (
                                      <PreparingIcon fontSize="small" />
                                    )}
                                  </Tooltip>
                                )}
                                <RouterLink
                                  variant="body1"
                                  to={reactRouterRouteService.resolveLocation(RouteTemplates.dashboard, [
                                    {
                                      name: RouteParamNames.configId,
                                      value: rowData.schoolConfiguration.id
                                    }
                                  ])}
                                >
                                  {rowData.schoolConfiguration.schoolName}
                                </RouterLink>
                                {rowData.schoolConfiguration.title.length > 0 && (
                                  <Typography variant="body2">{`[${rowData.schoolConfiguration.title}]`}</Typography>
                                )}
                                {rowData.schoolConfiguration.tags.map((tag) => (
                                  <Chip
                                    key={`tag-${tag}`}
                                    className={tagClassname}
                                    variant="outlined"
                                    color="info"
                                    size="small"
                                    label={tag}
                                  />
                                ))}
                              </Stack>
                              {/* Using customFilterAndSearch is overkill but ensures the same search logic. */}
                              {currentSearchText.length > 0 &&
                                CustomFilterUtils.customFilterAndSearch(currentSearchText, rowData, () => [
                                  rowData.schoolConfiguration.comments
                                ]) && (
                                  <Typography
                                    maxWidth="25vw"
                                    variant="caption"
                                    fontStyle="italic"
                                    noWrap
                                    textOverflow="ellipsis"
                                  >
                                    {rowData.schoolConfiguration.comments}
                                  </Typography>
                                )}
                            </Stack>
                          );
                        }
                      },
                      {
                        title: strings.activeStudents,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.activeStudents.lastWeekVariation -
                          b.schoolGlobalStats.activeStudents.lastWeekVariation,
                        render: (rowData: SchoolConfigurationInfo) => {
                          const variation = rowData.schoolGlobalStats.activeStudents.lastWeekVariation;
                          const ratioLabel =
                            rowData.schoolConfiguration.expectedStudentCount === 0
                              ? `${rowData.schoolGlobalStats.activeStudents.lastWeek} / ${rowData.schoolGlobalStats.accounts.studentTotal}`
                              : `${rowData.schoolGlobalStats.activeStudents.lastWeek} / ${rowData.schoolConfiguration.expectedStudentCount} (${rowData.schoolGlobalStats.accounts.studentTotal})`;

                          const icon =
                            variation > 0 ? (
                              <ArrowUp className={'arrowUp'} />
                            ) : variation < 0 ? (
                              <ArrowDown className={'arrowDown'} />
                            ) : undefined;

                          return (
                            <Row verticalContentAlignment="center">
                              <Typography variant="body1">{ratioLabel}</Typography>
                              {icon && (
                                <>
                                  {icon}
                                  <Typography variant="body2">{`${Math.abs(variation)}%`}</Typography>
                                </>
                              )}
                            </Row>
                          );
                        }
                      },
                      {
                        title: strings.activeTeachers,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.activeTeachers.lastWeekVariation -
                          b.schoolGlobalStats.activeTeachers.lastWeekVariation,
                        render: (rowData: SchoolConfigurationInfo) => {
                          const variation = rowData.schoolGlobalStats.activeTeachers.lastWeekVariation;
                          const ratioLabel = `${rowData.schoolGlobalStats.activeTeachers.lastWeek} / ${rowData.schoolGlobalStats.accounts.teacherTotal}`;

                          const icon =
                            variation > 0 ? (
                              <ArrowUp className={'arrowUp'} />
                            ) : variation < 0 ? (
                              <ArrowDown className={'arrowDown'} />
                            ) : undefined;

                          return (
                            <Row verticalContentAlignment="center">
                              <Typography variant="body1">{ratioLabel}</Typography>
                              {icon && (
                                <>
                                  {icon}
                                  <Typography variant="body2">{`${Math.abs(variation)}%`}</Typography>
                                </>
                              )}
                            </Row>
                          );
                        }
                      },
                      {
                        title: strings.activeParents,
                        width: '12%',
                        defaultSort: 'asc',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolGlobalStats.accounts.parentClaimed - b.schoolGlobalStats.accounts.parentClaimed,
                        render: (rowData: SchoolConfigurationInfo) => (
                          <Row verticalContentAlignment="center">
                            <Typography variant="body1">{`${rowData.schoolGlobalStats.accounts.parentClaimed} / ${rowData.schoolGlobalStats.accounts.parentTotal}`}</Typography>
                          </Row>
                        )
                      },
                      {
                        title: strings.startDate,
                        width: '12%',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolConfiguration.startDay.compare(b.schoolConfiguration.startDay),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <Typography variant="body1">
                              {rowData.schoolConfiguration.startDay.formattedString(
                                localizationService.localizedStrings.models.dateFormats.shortUnabridged
                              )}
                            </Typography>
                          );
                        }
                      },
                      {
                        title: strings.endDate,
                        width: '12%',
                        customSort: (a: SchoolConfigurationInfo, b: SchoolConfigurationInfo) =>
                          a.schoolConfiguration.endDay.compare(b.schoolConfiguration.endDay),
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <Typography variant="body1">
                              {rowData.schoolConfiguration.endDay.formattedString(
                                localizationService.localizedStrings.models.dateFormats.shortUnabridged
                              )}
                            </Typography>
                          );
                        }
                      },
                      {
                        title: strings.integrations,
                        width: '12%',
                        render: (rowData: SchoolConfigurationInfo) => {
                          const moreCount =
                            rowData.schoolConfiguration.enabledIntegrations.length > 4
                              ? rowData.schoolConfiguration.enabledIntegrations.length - 3
                              : 0;
                          const visibleIntegrations =
                            moreCount === 0
                              ? rowData.schoolConfiguration.enabledIntegrations
                              : _.take(rowData.schoolConfiguration.enabledIntegrations, 3);
                          const hiddenIntegrations =
                            moreCount === 0 ? [] : _.drop(rowData.schoolConfiguration.enabledIntegrations, 3);

                          return (
                            <Box display="flex" flexDirection="row">
                              {visibleIntegrations.map((integration) => (
                                <Tooltip
                                  key={integration}
                                  title={connectorStrings.localizedIntegrationName(integration)}
                                >
                                  <Box>
                                    <TintedImage
                                      source={externalImages.getIntegrationImage(integration)}
                                      sx={{
                                        width: 24,
                                        height: 24,
                                        marginRight: '6px'
                                      }}
                                    />
                                  </Box>
                                </Tooltip>
                              ))}
                              {moreCount > 0 && (
                                <Tooltip
                                  title={
                                    <Stack direction="row" p={1}>
                                      {hiddenIntegrations.map((integration) => (
                                        <TintedImage
                                          key={`more-integration-${integration}`}
                                          source={externalImages.getIntegrationImage(integration)}
                                          sx={{
                                            width: 24,
                                            height: 24,
                                            marginRight: '6px'
                                          }}
                                        />
                                      ))}
                                    </Stack>
                                  }
                                >
                                  <Typography>{`+${moreCount}`}</Typography>
                                </Tooltip>
                              )}
                            </Box>
                          );
                        }
                      },
                      {
                        title: strings.valid,
                        width: '1%',
                        render: (rowData: SchoolConfigurationInfo) => {
                          return (
                            <Typography variant="body1">
                              {rowData.validationMessages == null
                                ? ''
                                : rowData.validationMessages.length === 0
                                  ? '✅'
                                  : '❗'}
                            </Typography>
                          );
                        }
                      }
                    ]}
                    data={data}
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    actions={[{ icon: 'more_vert', onClick: () => {} }]}
                    options={{
                      pageSize: 50,
                      pageSizeOptions: DefaultTablePageSizes,
                      rowStyle: { backgroundColor: '#fff' },
                      headerStyle: {
                        fontSize: theme.typography.body2.fontSize
                      },
                      emptyRowsWhenPaging: false,
                      actionsColumnIndex: -1,
                      draggable: false
                    }}
                    localization={{
                      ...localizationService.localizedStrings.insights.materialTable,
                      header: { actions: '' }
                    }}
                  />
                </Box>
              </Card>
            </Box>
          )}
        />
      </Box>

      <Snackbar
        open={showToast}
        autoHideDuration={2000}
        onClose={() => setShowToast(false)}
        message={strings.copyIdNotification}
      />
    </Root>
  );
});

const Root = styled(Box)(() => ({
  '.card': {
    minWidth: MinLayoutWidth
  },
  '.yearSelection': {
    minWidth: 100
  },
  '.actionsRow': {
    marginLeft: 50
  },
  '.arrowUp': {
    fontSize: 18,
    lineHeight: 'normal',
    marginLeft: 8,
    color: green[500]
  },
  '.arrowDown': {
    fontSize: 18,
    lineHeight: 'normal',
    marginLeft: 8,
    color: red[500]
  }
}));
