import { AutomatedImportListViewModel } from '@insights/viewmodels';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import DetailsIcon from '@mui/icons-material/MoreHoriz';
import { Box, Button, Divider, ListItemIcon, ListItemText, MenuItem, SxProps, Typography } from '@mui/material';
import { AutomatedImport } from '@shared/models/import';
import { useNavigateAsync } from '@shared/utils';
import _ from 'lodash';
import { observer } from 'mobx-react-lite';
import { useInsightsServices } from '../../UseInsightsServicesHook';
import { AuthorizationRoleCondition } from '../AuthorizationRoleCondition';
import { MoreActionMenu } from '../MoreActionMenu';

export interface AutomatedImportItemProps {
  viewModel: AutomatedImportListViewModel;
  automatedImport: AutomatedImport;
  sx?: SxProps;
  className?: string;
  showActions?: boolean;
  hasDivider?: boolean;
}

export const AutomatedImportItem = observer((props: AutomatedImportItemProps) => {
  const { localizationService } = useInsightsServices();
  const { className, sx = [], viewModel, automatedImport, showActions = false, hasDivider = false } = props;
  const strings = localizationService.localizedStrings.insights.components.import;
  const navigate = useNavigateAsync();

  return (
    <Box sx={sx} className={className} flexDirection="column">
      <Box my={1} flexDirection="row" display="flex" alignItems="flex-start">
        <Box flex={1} flexDirection="column" px={1}>
          <Button
            sx={{ textTransform: 'none', p: 0, minWidth: 0 }}
            variant="text"
            onClick={() => void viewModel.editAutomatedTransformations(automatedImport, navigate)}
          >
            <Typography variant="subtitle1">{automatedImport.name}</Typography>
          </Button>
          <Typography variant="body1" sx={{ color: (theme) => theme.palette.text.secondary }}>
            {strings.lastAutomatedImport(_.last(automatedImport.history))}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ color: (theme) => theme.palette.text.secondary }}
            style={{ fontStyle: 'italic' }}
          >
            {strings.automatedImportTimes(automatedImport.days, automatedImport.time)}
          </Typography>
        </Box>

        {showActions && (
          <MoreActionMenu>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MenuItem onClick={() => void viewModel.editAutomatedImport(automatedImport)}>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText>{strings.editAutomatedImport}</ListItemText>
              </MenuItem>
            </AuthorizationRoleCondition>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MenuItem onClick={() => void viewModel.editAutomatedTransformations(automatedImport, navigate)}>
                <ListItemIcon>
                  <DetailsIcon />
                </ListItemIcon>
                <ListItemText>{strings.editAutomatedTransformations}</ListItemText>
              </MenuItem>
            </AuthorizationRoleCondition>
            <AuthorizationRoleCondition allowedRoles={['super-admin']}>
              <MenuItem onClick={() => void viewModel.deleteAutomatedImportRequest(automatedImport)}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <ListItemText>{strings.deleteAutomatedImport}</ListItemText>
              </MenuItem>
            </AuthorizationRoleCondition>
          </MoreActionMenu>
        )}
      </Box>

      {hasDivider && <Divider />}
    </Box>
  );
});
