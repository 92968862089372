import * as am4core from '@amcharts/amcharts4/core';
import { Box, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { configure } from 'mobx';
import { observer } from 'mobx-react-lite';
import { Fragment, useEffect } from 'react';
import { Outlet, useLocation } from 'react-router';
import './App.css';
import {
  ApplicationName,
  BrowserDetector,
  BrowserHistoryListener,
  DateTimePickerLocalizationProvider,
  ErrorBoundary,
  LoadingIndicator,
  UpdateIndicator
} from './components';
import { amChartTheme, LightInsightsMuiTheme } from './theme';
import { useInsightsServices } from './UseInsightsServicesHook';
import { UrlUtils } from './utils';

configure({
  enforceActions: 'observed'
});

am4core.options.commercialLicense = true;
am4core.useTheme(amChartTheme);

const App = observer(() => {
  const { modalService, localizationService, settingsStore, startUpService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights;
  const { isReady } = startUpService;
  const location = useLocation();

  useEffect(() => {
    settingsStore.useIOSAccessTokenProvider = UrlUtils.getUseAccessTokenProvider(location);

    // Set this before rendering any login page, to ensure the LoginViewModel knows early.
    settingsStore.isCompletingLogin = UrlUtils.getLoginCompletion(location);
    void startUpService.start();
  }, []);

  return (
    <ThemeProvider theme={createTheme({ cssVariables: true, ...LightInsightsMuiTheme })}>
      <>
        <DateTimePickerLocalizationProvider>
          <CssBaseline />

          <ErrorBoundary>
            <BrowserDetector>
              {!isReady ? (
                <Box flex={1} display="flex" flexDirection="column" alignItems="center">
                  <ApplicationName sx={{ marginTop: '20vh' }} size="extra-large" color="light" />
                  <LoadingIndicator sx={{ marginTop: '10vh' }} message={strings.initializingMessage} />
                </Box>
              ) : (
                <>
                  <BrowserHistoryListener />
                  <Box height="100%" width="100%" display="flex" flexDirection="column">
                    <UpdateIndicator />

                    <Box flex={1}>
                      <Outlet />

                      {modalService.modals.map((m, index) => (
                        <Fragment key={index}>{m.element}</Fragment>
                      ))}
                    </Box>
                  </Box>
                </>
              )}
            </BrowserDetector>
          </ErrorBoundary>
        </DateTimePickerLocalizationProvider>
      </>
    </ThemeProvider>
  );
});

export default App;
