import { ApplicationName, LoadingIndicator } from '@insights/components';
import CloseIcon from '@mui/icons-material/Close';
import ErrorIcon from '@mui/icons-material/Error';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  Link,
  Snackbar,
  SnackbarContent,
  Stack,
  SxProps,
  Typography,
  useTheme
} from '@mui/material';
import { Locale } from '@shared/resources/services';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { useInsightsServices } from '../UseInsightsServicesHook';

export interface LoginProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Login = observer((props: LoginProps) => {
  const { localizationService, viewModelFactory } = useInsightsServices();
  const { className, style, sx } = props;
  const strings = localizationService.localizedStrings.insights;
  const theme = useTheme();
  const viewModel = useMemo(() => viewModelFactory.createLogin(), []);
  const { isLoggingIn, isSigningUp } = viewModel;

  const location = useLocation();
  const navigate = useNavigateAsync();

  useEffect(() => {
    void viewModel.onInit(location, navigate);
  }, []);

  const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);
  const [showPrivacyAlert, setShowPrivacyAlert] = useState(false);

  function onSignUpClick() {
    if (hasAcceptedTerms) {
      void viewModel.signUp(location, navigate);
    } else {
      setShowPrivacyAlert(true);
    }
  }

  return (
    <Box
      sx={{ ...sx, backgroundColor: 'transparent' }}
      className={className}
      style={style}
      display="flex"
      flexDirection="column"
      alignItems="center"
      overflow="auto"
    >
      <Stack
        sx={{
          alignItems: 'center',
          pb: 2
        }}
      >
        <ApplicationName
          sx={{ marginTop: '20vh', ['@media screen and (max-height: 650px)']: { marginTop: 3 } }}
          size="large"
          color="light"
        />

        <Box display="flex" flexDirection="column" maxWidth={325} sx={{ backgroundColor: 'transparent' }}>
          <Button
            sx={{ marginTop: 4, minWidth: 200 }}
            disabled={isSigningUp || isLoggingIn}
            variant="contained"
            color="primary"
            onClick={onSignUpClick}
          >
            {isSigningUp && <LoadingIndicator size="tiny" color="secondary" />}
            {!isSigningUp && strings.signUp}
          </Button>

          {!hasAcceptedTerms && showPrivacyAlert && (
            <Typography variant="caption" sx={{ color: (theme) => theme.palette.error.main }} mt={1}>
              {strings.privacyPolicyAlertText}
            </Typography>
          )}

          <FormControlLabel
            sx={{ marginTop: 1 }}
            control={<Checkbox />}
            checked={hasAcceptedTerms}
            onChange={(_, checked) => setHasAcceptedTerms(checked)}
            label={
              <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary }}>
                <div dangerouslySetInnerHTML={{ __html: strings.privacyPolicyConfirmMessage }} />
              </Typography>
            }
          />

          <Button
            sx={{ marginTop: 6, minWidth: 200 }}
            disabled={isLoggingIn || isSigningUp}
            variant="outlined"
            color="primary"
            size="small"
            onClick={() => void viewModel.login(location, navigate)}
          >
            {isLoggingIn && <LoadingIndicator size="tiny" color="secondary" />}
            {!isLoggingIn && strings.login}
          </Button>
        </Box>
        <Box marginTop={4}>
          <Link underline="none" onClick={() => void viewModel.switchLanguage()}>
            <Typography variant="body2" sx={{ fontWeight: 400, color: theme.palette.secondary.dark }}>
              {titleOfSwitchLocaleButton(viewModel.currentLocale)}
            </Typography>
          </Link>
        </Box>

        <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary, mt: 4 }} align="center">
          {viewModel.version}
        </Typography>
        <Typography variant="caption" sx={{ color: (theme) => theme.palette.text.secondary, mt: 0.5 }} align="center">
          {viewModel.copyright}
        </Typography>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={viewModel.hasError}
          onClose={(_, reason) => {
            if (reason !== 'clickaway') {
              viewModel.hasError = false;
            }
          }}
        >
          <SnackbarContent
            message={
              <Box display="flex" flexDirection="row" alignItems="center">
                <ErrorIcon sx={{ fontSize: 20 }} />

                <Box marginLeft={1} marginRight={1}>
                  {strings.loginError}
                </Box>

                <IconButton color="inherit" onClick={() => (viewModel.hasError = false)}>
                  <CloseIcon sx={{ fontSize: 20 }} />
                </IconButton>
              </Box>
            }
          />
        </Snackbar>
      </Stack>
    </Box>
  );
});

function titleOfSwitchLocaleButton(currentLocale: Locale): string {
  // No need for localization
  switch (currentLocale) {
    case 'en':
      return 'Français';
    case 'fr':
      return 'English';
  }
}
