import { EditableSectionList, ObservablePresenter, PageHeaderBar, SectionList } from '@insights/components';
import { Box, Card, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { MinLayoutWidth } from '../../../Constants';
import { useInsightsServices } from '../../../UseInsightsServicesHook';

export interface SectionsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
  canEdit?: boolean;
}

export const Sections = observer((props: SectionsProps) => {
  const { analyticsService, localizationService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style, canEdit = false } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSections(configId), [configId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Section List' });
  });

  return (
    <Box sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <Box flex={1}>
        <ObservablePresenter
          sx={{ p: 2, width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingSectionsMessage}
          errorMessage={strings.loadingSectionsErrorMessage}
          render={(data) => (
            <Box display="flex" flexDirection="column">
              <Card sx={{ minWidth: MinLayoutWidth }}>
                {canEdit ? (
                  <EditableSectionList
                    sx={{ width: '100%', height: '100%' }}
                    title={strings.sections}
                    sections={data}
                    configId={viewModel.configId}
                  />
                ) : (
                  <SectionList
                    sx={{ width: '100%', height: '100%' }}
                    title={strings.sections}
                    sections={data}
                    configId={viewModel.configId}
                  />
                )}
              </Card>
            </Box>
          )}
        />
      </Box>
    </Box>
  );
});
