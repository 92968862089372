import {
  InsightsMaterialTable,
  LoadingIndicator,
  MoreActionMenu,
  OptionalRouterLink,
  PageHeaderBar,
  RouterLink,
  UserInfo
} from '@insights/components';
import { caseInsensitiveAccentInsensitiveCompare, cleanDiacritics } from '@insights/utils';
import { UsersTableRowInfo } from '@insights/viewmodels';
import { MTableAction } from '@material-table/core';
import DeletedIcon from '@mui/icons-material/Cancel';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import {
  Box,
  Button,
  Card,
  Chip,
  ListItemIcon,
  ListItemText,
  MenuItem,
  styled,
  SxProps,
  TextField,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { AccountUtils } from '@shared/components/utils';
import { SchoolYearConfigurationUtils } from '@shared/components/utils/models/SchoolYearConfigurationUtils';
import { Role } from '@shared/models/types';
import { useNavigateAsync } from '@shared/utils';
import { observer } from 'mobx-react-lite';
import { ChangeEvent, CSSProperties, FormEvent, useMemo } from 'react';
import {
  DefaultTablePageSize,
  DefaultTablePageSizes,
  MinLayoutWidth,
  ParentsColor,
  StudentsColor,
  TeachersColor,
  TeachersLightColor
} from '../../Constants';
import { RouteParamNames, RouteTemplates } from '../../Routes';
import { useInsightsServices } from '../../UseInsightsServicesHook';

const TableStateKey = 'Users';

export interface UsersProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const Users = observer((props: UsersProps) => {
  const { accountService, localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;
  const strings = localizationService.localizedStrings.insights.views;
  const theme = useTheme();
  const isSuperAdmin = accountService.isAllowed(['super-admin']);

  const viewModel = useMemo(() => viewModelFactory.createUsers(), []);
  const navigate = useNavigateAsync();

  function onUserIdOrEmailChange(event: ChangeEvent<HTMLInputElement>) {
    viewModel.userIdOrEmail = event.target.value;
  }

  function onSearchTextChange(event: ChangeEvent<HTMLInputElement>) {
    viewModel.searchText = event.target.value;
  }

  function onSearchClick(event: FormEvent) {
    event.preventDefault();
    void viewModel.search();
  }

  return (
    <Root sx={sx} className={className} style={style} display="flex" flexDirection="column">
      <PageHeaderBar />

      <Box padding={2} flex={1} display="flex" flexDirection="column">
        <Box paddingBottom={2}>
          <Card className={'card'}>
            <Box height="100%" width="100%">
              <form onSubmit={onSearchClick}>
                <Box display="flex" flexDirection="row" alignItems="center">
                  <TextField
                    label={strings.idOrEmail}
                    className={'textField'}
                    disabled={!viewModel.isTextInputEnabled}
                    autoFocus
                    value={viewModel.userIdOrEmail}
                    onChange={onUserIdOrEmailChange}
                  />
                  <TextField
                    label={strings.searchText}
                    className={'textField'}
                    disabled={!viewModel.isTextInputEnabled}
                    value={viewModel.searchText}
                    onChange={onSearchTextChange}
                  />
                  <Button
                    variant={'contained'}
                    color={'secondary'}
                    type={'submit'}
                    className={'button'}
                    disabled={!viewModel.canSearch}
                  >
                    {strings.search}
                  </Button>
                  {viewModel.isSearching && <LoadingIndicator size={'small'} />}
                </Box>
              </form>
            </Box>
          </Card>
        </Box>

        <Box flex={1} overflow="auto">
          <Card className={'card'}>
            <Box height="100%" width="100%">
              <InsightsMaterialTable
                stateKey={TableStateKey}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                actions={[{ icon: 'more_vert', onClick: () => {} }]}
                // This is to disable the card contour
                components={{
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  Container: (p) => <div style={{ background: '#fff' }}>{p.children}</div>,
                  Action: (p) => {
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                    if (!p.action.isFreeAction) {
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                      const data = p.data as UsersTableRowInfo;

                      return (
                        <MoreActionMenu>
                          {isSuperAdmin && (
                            <MenuItem
                              onClick={() =>
                                data.account != null &&
                                void viewModel.navigateToPlanner(data.account.configId, data.account.id)
                              }
                            >
                              <ListItemText primary={strings.viewPlanner} />
                              <ListItemIcon className={'viewPlannerIcon'}>
                                <OpenInNewIcon />
                              </ListItemIcon>
                            </MenuItem>
                          )}

                          <MenuItem onClick={() => void viewModel.editAccount(data)}>
                            <ListItemText primary={strings.editAccountDetails} />
                          </MenuItem>

                          {(data.account?.role === 'student' || data.account?.role === 'teacher') && (
                            <MenuItem onClick={() => void viewModel.editSelectedSections(data)}>
                              <ListItemText primary={strings.editAccountSections} />
                            </MenuItem>
                          )}

                          {isSuperAdmin && (
                            <MenuItem
                              onClick={() =>
                                data.account?.configurationSummary != null &&
                                void viewModel.editSchool(data.account.configurationSummary.id, navigate)
                              }
                            >
                              <ListItemText primary={strings.manageThisSchool} />
                            </MenuItem>
                          )}

                          {isSuperAdmin && (
                            <MenuItem
                              onClick={() => void viewModel.deleteUser(data.userProfile)}
                              disabled={data.account != null}
                            >
                              <ListItemText primary={strings.deleteUser} />
                            </MenuItem>
                          )}
                        </MoreActionMenu>
                      );
                    }

                    return <MTableAction {...p} />;
                  }
                }}
                title={viewModel.partialResults ? strings.usersPartial : strings.users}
                data={viewModel.usersTableRowInfos}
                options={{
                  pageSize: DefaultTablePageSize,
                  pageSizeOptions: DefaultTablePageSizes,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.isChild ? '#eee' : '#fff',
                    verticalAlign: 'center',
                    borderTopWidth: rowData.isFirstFromProfile ? 2 : 0,
                    borderTopStyle: 'solid'
                  }),
                  headerStyle: { fontSize: theme.typography.body2.fontSize },
                  emptyRowsWhenPaging: false,
                  actionsColumnIndex: -1,
                  draggable: false
                }}
                // Remove the "Actions" column title
                localization={{
                  ...localizationService.localizedStrings.insights.materialTable,
                  header: { actions: '' }
                }}
                columns={[
                  {
                    title: strings.username,
                    customSort: (a: UsersTableRowInfo, b: UsersTableRowInfo) => {
                      if (!a.userProfile.username.trim()) {
                        return 1;
                      }

                      if (!b.userProfile.username.trim()) {
                        return -1;
                      }

                      return caseInsensitiveAccentInsensitiveCompare(a.userProfile.username, b.userProfile.username);
                    },
                    customFilterAndSearch: (filter: string, data: UsersTableRowInfo) => {
                      const cleanedValue = cleanDiacritics(data.userProfile.username);
                      const cleanedFilter = cleanDiacritics(filter);

                      return cleanedValue.toLowerCase().includes(cleanedFilter.toLowerCase());
                    },
                    render: (rowInfo: UsersTableRowInfo) => <UserInfo profile={rowInfo.userProfile} />
                  },
                  {
                    title: strings.email,
                    customSort: (a: UsersTableRowInfo, b: UsersTableRowInfo) => {
                      if (!a.userProfile.email.trim()) {
                        return 1;
                      }

                      if (!b.userProfile.email.trim()) {
                        return -1;
                      }

                      return caseInsensitiveAccentInsensitiveCompare(a.userProfile.email, b.userProfile.email);
                    },
                    customFilterAndSearch: (filter: string, data: UsersTableRowInfo) => {
                      const cleanedValue = cleanDiacritics(data.userProfile.email);
                      const cleanedFilter = cleanDiacritics(filter);

                      return cleanedValue.toLowerCase().includes(cleanedFilter.toLowerCase());
                    },
                    render: (rowInfo: UsersTableRowInfo) => (
                      <Box display="flex" flexDirection="row">
                        <Box marginRight={0.5}>
                          {rowInfo.userProfile.isEmailConfirmed && <VerifiedUserIcon color={'secondary'} />}
                        </Box>
                        <Typography variant="body1">{rowInfo.userProfile.email}</Typography>
                      </Box>
                    )
                  },
                  {
                    title: strings.configuration,
                    customSort: (a: UsersTableRowInfo, b: UsersTableRowInfo) => {
                      const resolvedA = a.account?.configurationSummary?.schoolName ?? '';
                      const resolvedB = b.account?.configurationSummary?.schoolName ?? '';

                      if (resolvedA.trim().length === 0) {
                        return 1;
                      }

                      if (resolvedA.trim().length === 0) {
                        return -1;
                      }

                      return caseInsensitiveAccentInsensitiveCompare(resolvedA, resolvedB);
                    },
                    customFilterAndSearch: (filter: string, data: UsersTableRowInfo) => {
                      const cleanedValue = cleanDiacritics(data.account?.configurationSummary?.schoolName ?? '');
                      const cleanedFilter = cleanDiacritics(filter);

                      return cleanedValue.toLowerCase().includes(cleanedFilter.toLowerCase());
                    },
                    render: (rowInfo: UsersTableRowInfo) =>
                      rowInfo.account?.configurationSummary != null && (
                        <Box display="flex" flexDirection="column">
                          <RouterLink
                            to={reactRouterRouteService.resolveLocation(RouteTemplates.schoolRoot, [
                              {
                                name: RouteParamNames.configId,
                                value: rowInfo.account.configId
                              }
                            ])}
                            color="primary"
                          >
                            <Box display="flex" flexDirection="row" alignItems="center">
                              <Box marginRight={0.5}>
                                <Typography variant="body1" sx={{ color: (theme) => theme.palette.primary.main }}>
                                  {rowInfo.account.configurationSummary.schoolName}
                                </Typography>
                              </Box>

                              <Typography
                                variant="body2"
                                sx={{ color: (theme) => theme.palette.primary.main }}
                              >{`[${SchoolYearConfigurationUtils.displayTitle(
                                rowInfo.account.configurationSummary
                              )}]`}</Typography>
                            </Box>
                          </RouterLink>
                          <code className={'identifier'}>{rowInfo.account.configId}</code>
                        </Box>
                      )
                  },
                  {
                    title: strings.accounts,
                    customSort: (a: UsersTableRowInfo, b: UsersTableRowInfo) => {
                      if (a.account == null || a.account.lastName.trim().length === 0) {
                        return 1;
                      }

                      if (b.account == null || b.account.lastName.trim().length === 0) {
                        return -1;
                      }

                      return (
                        caseInsensitiveAccentInsensitiveCompare(a.account.lastName, b.account.lastName) ||
                        caseInsensitiveAccentInsensitiveCompare(a.account.firstName, b.account.firstName)
                      );
                    },
                    customFilterAndSearch: (filter: string, data: UsersTableRowInfo) => {
                      const cleanedValue = cleanDiacritics(data.account?.lastName ?? '');
                      const cleanedFilter = cleanDiacritics(filter);

                      return cleanedValue.toLowerCase().includes(cleanedFilter.toLowerCase());
                    },
                    render: (rowInfo: UsersTableRowInfo) => {
                      const isTeacherOrStudent =
                        rowInfo.account && (rowInfo.account.role === 'teacher' || rowInfo.account.role === 'student');

                      return (
                        rowInfo.account != null && (
                          <Box display="flex" flexDirection="row" alignItems="center">
                            <Box marginRight={1}>
                              <Chip
                                color="primary"
                                variant="outlined"
                                classes={{
                                  root: 'accountRoleChip',
                                  label: 'accountRoleChipLabel',
                                  outlined: chipClassFromRole(rowInfo.account.role)
                                }}
                                label={rowInfo.account.role}
                              />
                            </Box>
                            {rowInfo.account.isDeleted && (
                              <Tooltip title={strings.deletedAccount}>
                                <DeletedIcon className={'deletedIcon'} fontSize="small" color="error" />
                              </Tooltip>
                            )}
                            <Box display="flex" flexDirection="column">
                              <OptionalRouterLink
                                disabled={!isTeacherOrStudent}
                                color={isTeacherOrStudent ? 'primary' : undefined}
                                to={reactRouterRouteService.resolveLocation(
                                  rowInfo.account.role === 'student'
                                    ? RouteTemplates.studentDetails
                                    : RouteTemplates.teacherDetails,
                                  [
                                    {
                                      name: RouteParamNames.configId,
                                      value: rowInfo.account.configId
                                    },
                                    {
                                      name:
                                        rowInfo.account.role === 'student'
                                          ? RouteParamNames.studentId
                                          : RouteParamNames.teacherId,
                                      value: rowInfo.account.id
                                    }
                                  ]
                                )}
                              >
                                <Typography
                                  variant="body1"
                                  color={isTeacherOrStudent ? 'primary' : undefined}
                                >{`${AccountUtils.getDisplayFirstLastName(
                                  rowInfo.account,
                                  localizationService.localizedStrings.insights.noName
                                )}`}</Typography>
                              </OptionalRouterLink>

                              {rowInfo.account.email.length > 0 && (
                                <Typography variant="body1">{rowInfo.account.email}</Typography>
                              )}
                              <code className={'identifier'}>{rowInfo.account.id}</code>
                            </Box>
                          </Box>
                        )
                      );
                    }
                  }
                ]}
              />
            </Box>
          </Card>
        </Box>
      </Box>
    </Root>
  );
});

function chipClassFromRole(role: Role) {
  switch (role) {
    case 'teacher':
      return 'accountRoleChipOutlineTeacher';
    case 'student':
      return 'accountRoleChipOutlineStudent';
    case 'parent':
      return 'accountRoleChipOutlineParent';
    default:
      return 'accountRoleChipOutlineIndividual';
  }
}

const Root = styled(Box)(({ theme }) => ({
  '.card': {
    minWidth: MinLayoutWidth
  },
  '.viewPlannerIcon': {
    margin: 0,
    marginLeft: theme.spacing(3)
  },
  '.textField': {
    marginLeft: 8,
    marginRight: 8,
    width: 325
  },
  '.identifier': {
    color: theme.palette.grey[400]
  },
  '.accountRoleChip': {
    // This value is adequate for all possible roles in the current typography (accountRoleChipLabel below)
    minWidth: 80
  },
  '.deletedIcon': {
    marginRight: theme.spacing(1)
  },
  '.accountRoleChipOutlineTeacher': {
    borderColor: TeachersColor
  },
  '.accountRoleChipOutlineStudent': {
    borderColor: StudentsColor
  },
  '.accountRoleChipOutlineParent': {
    borderColor: ParentsColor
  },
  '.accountRoleChipOutlineIndividual': {
    borderColor: TeachersLightColor
  },
  accountRoleChipLabel: {
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.caption.fontWeight
  },
  '.button': {
    marginLeft: 8,
    marginRight: 8
  }
}));
