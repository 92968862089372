import { AlertService, NavigationService } from '@insights/services';
import { AccountModel, SchoolYearConfigurationModel } from '@shared/models/config';
import { Day } from '@shared/models/types';
import { LocalizationService } from '@shared/resources/services';
import { SchoolYearConfigurationStore } from '@shared/services/stores';
import { NavigateFunctionAsync } from '@shared/utils';
import { chain } from 'lodash';
import { computed, makeObservable } from 'mobx';
import { IPromiseBasedObservable, fromPromise } from 'mobx-utils';
import { Thresholds } from '../../../Constants';

export interface SchoolYearConfigurationScreenThresholdInfo {
  gradeLevel: string;
  dailyThreshold: number;
  weeklyThreshold: number;
}

export interface SchoolYearConfigurationScreenAssessmentPlanningInfo {
  gradeLevel: string;
  visibilityDate: Day;
}

export interface SchoolYearConfigurationScreenInfo {
  gradeLevels: string[];
  schoolYearConfiguration: SchoolYearConfigurationModel;
  thresholds: SchoolYearConfigurationScreenThresholdInfo[];
  visibilityDates: SchoolYearConfigurationScreenAssessmentPlanningInfo[];
  individualAccount?: AccountModel;
  validationResults: string[] | undefined;
}

export interface SchoolYearConfigurationScreenViewModel {
  readonly configId: string;
  readonly data: IPromiseBasedObservable<SchoolYearConfigurationScreenInfo>;

  editAssessmentPlanning: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  editDates: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  editInformation: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  editLinks: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  editThresholds: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  editIndividualAccount: (accountId: string) => Promise<void>;

  clearCache: () => Promise<void>;
  createDemoCopy: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
  createNextYearCopy: (configId: string, navigate: NavigateFunctionAsync) => Promise<void>;
  purgeDeletedAccounts: (schoolYearConfiguration: SchoolYearConfigurationModel) => Promise<void>;
}

export class AppSchoolYearConfigurationScreenViewModel implements SchoolYearConfigurationScreenViewModel {
  constructor(
    public readonly configId: string,
    private readonly _schoolYearConfigurationStore: SchoolYearConfigurationStore,
    private readonly _navigationService: NavigationService,
    private readonly _alertService: AlertService,
    private readonly _localizationService: LocalizationService
  ) {
    makeObservable(this);
  }

  @computed
  get data(): IPromiseBasedObservable<SchoolYearConfigurationScreenInfo> {
    return fromPromise(this.loadData());
  }

  async editAssessmentPlanning(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToEditSchoolYearConfigurationAssessmentPlanning(schoolYearConfiguration);
  }

  async editDates(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToEditSchoolYearConfigurationDates(schoolYearConfiguration);
  }

  async editInformation(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToEditSchoolYearConfigurationInformation(schoolYearConfiguration);
  }

  async editLinks(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToEditSchoolYearConfigurationLinks(schoolYearConfiguration);
  }

  async editThresholds(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    const [sectionsGradeLevels, studentsGradeLevels] = await Promise.all([
      this._schoolYearConfigurationStore.getSectionsGradeLevels(this.configId),
      this._schoolYearConfigurationStore.getStudentsGradeLevels(this.configId, false)
    ]);

    await this._navigationService.navigateToEditSchoolYearConfigurationThresholds(
      schoolYearConfiguration,
      sectionsGradeLevels,
      studentsGradeLevels
    );
  }

  async editIndividualAccount(accountId: string): Promise<void> {
    await this._navigationService.navigateToAccountEdition(this.configId, accountId);
  }

  async clearCache(): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    try {
      await this._schoolYearConfigurationStore.clearCachedConfig(this.configId);
      await this._alertService.showMessage({
        title: strings.clearCacheResultTitle,
        message: strings.clearCacheResultSuccess
      });
    } catch (error) {
      await this._alertService.showMessage({
        title: strings.clearCacheResultTitle,
        message: strings.clearCacheResultError(error as Error)
      });
    }
  }

  async createDemoCopy(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    await this._navigationService.navigateToCreateDemoCopy(schoolYearConfiguration);
  }

  async createNextYearCopy(configId: string, navigate: NavigateFunctionAsync): Promise<void> {
    await this._navigationService.navigateToNewSchool(navigate, configId);
  }

  async purgeDeletedAccounts(schoolYearConfiguration: SchoolYearConfigurationModel): Promise<void> {
    const strings = this._localizationService.localizedStrings.insights.viewModels.schoolYearConfiguration;

    const response = await this._alertService.showConfirmation({
      title: strings.confirmPurgeDeletedAccountsTitle,
      message: strings.confirmPurgeDeletedAccountsMessage,
      okButtonCaption: strings.confirmPurgeButton,
      cancelButtonCaption: strings.cancelPurgeButton
    });

    if (response !== 'cancelled') {
      try {
        const count = await this._schoolYearConfigurationStore.purgeDeletedAccounts(schoolYearConfiguration.id);
        await this._alertService.showMessage({
          title: strings.purgeDeletedAccountsSuccessTitle,
          message: strings.purgeDeletedAccountsSuccessMessage(count)
        });
      } catch (error) {
        await this._alertService.showMessage({
          title: strings.purgeDeletedAccountsErrorTitle,
          message: strings.purgeDeletedAccountsErrorMessage(error as Error)
        });
      }
    }
  }

  private async loadData(): Promise<SchoolYearConfigurationScreenInfo> {
    const [config, sectionsGradeLevels, studentsGradeLevels] = await Promise.all([
      this._schoolYearConfigurationStore.getConfig(this.configId),
      this._schoolYearConfigurationStore.getSectionsGradeLevels(this.configId),
      this._schoolYearConfigurationStore.getStudentsGradeLevels(this.configId, false)
    ]);

    const gradeLevels = config.gradeLevelSource === 'account' ? studentsGradeLevels : sectionsGradeLevels;
    const thresholds = chain(gradeLevels)
      .map((gradeLevel) => {
        const threshold = config.workloadThresholds.find((t) => t.gradeLevel === gradeLevel);

        return {
          gradeLevel,
          dailyThreshold:
            (threshold?.dailyThreshold ?? config.dailyWorkloadThreshold) || Thresholds.defaultDailyThreshold,
          weeklyThreshold:
            (threshold?.weeklyThreshold ?? config.weeklyWorkloadThreshold) || Thresholds.defaultWeeklyThreshold
        };
      })
      .value();

    const visibilityDates = chain(sectionsGradeLevels)
      .map((gradeLevel) => {
        const setting = config.assessmentPlanningSettings.find((p) => p.gradeLevel === gradeLevel);

        return {
          gradeLevel: gradeLevel,
          visibilityDate: setting?.examsAndImportantVisibilityDay ?? config.endDay
        };
      })
      .value();

    let individualAccount: AccountModel | undefined = undefined;
    if (config.type === 'standalone') {
      const accounts = await this._schoolYearConfigurationStore.getAccountsForRoles(
        this.configId,
        ['individual'],
        true
      );

      // Always assume there can't be more than one.
      individualAccount = accounts[0];
    }

    const validationResults = await this._schoolYearConfigurationStore.validateConfig(this.configId);

    return {
      gradeLevels: gradeLevels,
      schoolYearConfiguration: config,
      thresholds: thresholds,
      visibilityDates: visibilityDates,
      individualAccount,
      validationResults
    };
  }
}
