import {
  GrpcDashboard,
  GrpcDashboardProcessComments,
  GrpcOnboardingAnswer,
  GrpcOnboardingComment,
  GrpcOnboardingHistoryEntry,
  GrpcOnboardingProcess,
  GrpcOnboardingQuestion,
  GrpcOnboardingStep
} from '@shared/models/onboarding/implementations';
import {
  Dashboard,
  DashboardProcessComments,
  OnboardingAnswer,
  OnboardingComment,
  OnboardingHistoryEntry,
  OnboardingProcess,
  OnboardingQuestion,
  OnboardingStep,
  OnboardingText,
  QuestionChoice,
  SensitiveAnswer
} from '@shared/models/onboarding/interfaces';
import { OnboardingParticipantKind, OnboardingQuestionKind, OnboardingStatus } from '@shared/models/types';
import {
  protobufFromDate,
  protobufFromOnboardingParticipantKind,
  protobufFromOnboardingQuestionKind,
  protobufFromOnboardingStatus
} from '@shared/models/types/EnumConversion';
import { dataUrlToBlob } from '@shared/utils';
import { OnboardingTransport } from '../../transports';
import { OnboardingStore } from '../interfaces';
import { AppBaseStore } from './AppBaseStore';

// Note: This store does not memoize information, but still can be invalidated
//       to inform consumers that something was added or removed.
export class AppOnboardingStore extends AppBaseStore implements OnboardingStore {
  constructor(private readonly _transport: OnboardingTransport) {
    super('AppOnboardingStore');
  }

  async getProcessTemplateNames(): Promise<string[]> {
    return await this.withInvalidate(() => this._transport.getProcessTemplateNames());
  }

  async getStepTemplateNames(): Promise<string[]> {
    return await this.withInvalidate(() => this._transport.getStepTemplateNames());
  }

  async getQuestionTemplateNames(): Promise<string[]> {
    return await this.withInvalidate(() => this._transport.getQuestionTemplateNames());
  }

  async getQuestionTemplates(): Promise<OnboardingQuestion[]> {
    const pbTemplates = await this.withInvalidate(() => this._transport.getQuestionTemplates());
    return pbTemplates.map((pb) => new GrpcOnboardingQuestion(pb));
  }

  async createOrUpdateProcessTemplate(
    name: string,
    descriptions: OnboardingText[],
    stepNames: string[]
  ): Promise<void> {
    await this._transport.createOrUpdateProcessTemplate(
      name,
      descriptions.map((d) => d.toProtobuf()),
      stepNames
    );
    this.invalidate();
  }

  async createOrUpdateStepTemplate(
    name: string,
    participants: OnboardingParticipantKind,
    titles: OnboardingText[],
    descriptions: OnboardingText[],
    targetDays: number,
    questionNames: string[],
    isRepeatable: boolean,
    dependantStepName: string,
    dependantQuestionName: string,
    dependantQuestionAnswer: string
  ): Promise<void> {
    await this._transport.createOrUpdateStepTemplate(
      name,
      protobufFromOnboardingParticipantKind(participants),
      titles.map((t) => t.toProtobuf()),
      descriptions.map((d) => d.toProtobuf()),
      targetDays,
      questionNames,
      isRepeatable,
      dependantStepName,
      dependantQuestionName,
      dependantQuestionAnswer
    );
    this.invalidate();
  }

  async createOrUpdateQuestionTemplate(
    name: string,
    descriptions: OnboardingText[],
    kind: OnboardingQuestionKind,
    choices: QuestionChoice[],
    isRequired: boolean,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    isHiddenWhenDependant: boolean
  ): Promise<void> {
    await this._transport.createOrUpdateQuestionTemplate(
      name,
      descriptions.map((d) => d.toProtobuf()),
      protobufFromOnboardingQuestionKind(kind),
      choices.map((c) => c.toProtobuf()),
      isRequired,
      dependantQuestionName,
      dependantQuestionAnswer,
      isHiddenWhenDependant
    );
    this.invalidate();
  }

  async getProcess(processName: string, configId: string): Promise<OnboardingProcess> {
    return await this.withInvalidate(async () => {
      const pbProcess = await this._transport.getProcess(processName, configId);
      return new GrpcOnboardingProcess(pbProcess);
    });
  }

  async getProcesses(configId: string): Promise<OnboardingProcess[]> {
    return await this.withInvalidate(async () => {
      const pbProcesses = await this._transport.getProcesses(configId);
      return pbProcesses.map((pb) => new GrpcOnboardingProcess(pb));
    });
  }

  async createProcess(processName: string, configId: string): Promise<OnboardingProcess> {
    const pbProcess = await this._transport.createProcess(processName, configId);
    this.invalidate();
    return new GrpcOnboardingProcess(pbProcess);
  }

  async updateProcess(
    processName: string,
    configId: string,
    description: OnboardingText[],
    stepNames: string[],
    alsoUpdateTemplate: boolean
  ): Promise<OnboardingProcess> {
    const pbProcess = await this._transport.updateProcess(
      processName,
      configId,
      description.map((d) => d.toProtobuf()),
      stepNames,
      alsoUpdateTemplate
    );
    // No need to invalidate, the replacing process is returned.
    return new GrpcOnboardingProcess(pbProcess);
  }

  async updateProcessStatus(
    processName: string,
    configId: string,
    status: OnboardingStatus
  ): Promise<OnboardingProcess> {
    const pbProcess = await this._transport.updateProcessStatus(
      processName,
      configId,
      protobufFromOnboardingStatus(status)
    );
    // No need to invalidate, the replacing process is returned.
    return new GrpcOnboardingProcess(pbProcess);
  }

  async updateProcessAssignees(
    processName: string,
    configId: string,
    clientId: string | undefined,
    agentId: string | undefined,
    followerIds: string[]
  ): Promise<OnboardingProcess> {
    const pbProcess = await this._transport.updateProcessAssignees(
      processName,
      configId,
      clientId,
      agentId,
      followerIds
    );
    // No need to invalidate, the replacing process is returned.
    return new GrpcOnboardingProcess(pbProcess);
  }

  async renameProcess(processName: string, configId: string, newName: string): Promise<OnboardingProcess> {
    const pbProcess = await this._transport.renameProcess(processName, configId, newName);
    // No need to invalidate, the renamed process is returned.
    return new GrpcOnboardingProcess(pbProcess);
  }

  async deleteProcess(processName: string, configId: string): Promise<void> {
    await this._transport.deleteProcess(processName, configId);
    this.invalidate();
  }

  async getStep(stepName: string, configId: string): Promise<OnboardingStep> {
    return await this.withInvalidate(async () => {
      const pbStep = await this._transport.getStep(stepName, configId);
      return new GrpcOnboardingStep(pbStep);
    });
  }

  async createStep(stepName: string, configId: string): Promise<OnboardingStep> {
    const pbStep = await this._transport.createStep(stepName, configId);
    // We do not invalidate, the process will also update its steps list.
    return new GrpcOnboardingStep(pbStep);
  }

  async updateStep(
    stepName: string,
    configId: string,
    processName: string,
    participants: OnboardingParticipantKind,
    title: OnboardingText[],
    description: OnboardingText[],
    targetDays: number,
    questionNames: string[],
    isRepeatable: boolean,
    dependantStepName: string,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    alsoUpdateTemplate: boolean
  ): Promise<OnboardingStep> {
    const pbStep = await this._transport.updateStep(
      stepName,
      configId,
      processName,
      protobufFromOnboardingParticipantKind(participants),
      title.map((t) => t.toProtobuf()),
      description.map((d) => d.toProtobuf()),
      targetDays,
      questionNames,
      isRepeatable,
      dependantStepName,
      dependantQuestionName,
      dependantQuestionAnswer,
      alsoUpdateTemplate
    );
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async updateStepStatus(
    stepName: string,
    configId: string,
    processName: string,
    status: OnboardingStatus
  ): Promise<OnboardingStep> {
    const pbStep = await this._transport.updateStepStatus(
      stepName,
      configId,
      processName,
      protobufFromOnboardingStatus(status)
    );
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async updateStepTargetDate(
    stepName: string,
    configId: string,
    processName: string,
    newTargetDate: Date
  ): Promise<OnboardingStep> {
    const pbStep = await this._transport.updateStepTargetDate(
      stepName,
      configId,
      processName,
      protobufFromDate(newTargetDate)
    );
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async repeatStep(stepName: string, configId: string, processName: string): Promise<OnboardingStep> {
    const pbStep = await this._transport.repeatStep(stepName, configId, processName);
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async updateStepAssignees(
    stepName: string,
    configId: string,
    processName: string,
    clientId: string | undefined,
    agentId: string | undefined,
    followerIds: string[]
  ): Promise<OnboardingStep> {
    const pbStep = await this._transport.updateStepAssignees(
      stepName,
      configId,
      processName,
      clientId,
      agentId,
      followerIds
    );
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async renameStep(stepName: string, configId: string, newName: string): Promise<OnboardingStep> {
    const pbStep = await this._transport.renameStep(stepName, configId, newName);
    // No need to invalidate, the renamed step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async deleteStep(stepName: string, configId: string): Promise<void> {
    await this._transport.deleteStep(stepName, configId);
    this.invalidate();
  }

  async updateStepVisibility(
    stepName: string,
    configId: string,
    processName: string,
    isForcedVisible: boolean
  ): Promise<OnboardingStep> {
    const pbStep = await this._transport.updateStepVisibility(stepName, configId, processName, isForcedVisible);
    return new GrpcOnboardingStep(pbStep);
  }

  async createQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    kind: OnboardingQuestionKind
  ): Promise<OnboardingStep> {
    const pbPair = await this._transport.createQuestion(
      questionName,
      configId,
      stepName,
      protobufFromOnboardingQuestionKind(kind)
    );
    // No need to invalidate, the step with the newly added question is returned.
    return new GrpcOnboardingStep(pbPair.step);
  }

  async updateQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    description: OnboardingText[],
    kind: OnboardingQuestionKind,
    choices: QuestionChoice[],
    isRequired: boolean,
    dependantQuestionName: string,
    dependantQuestionAnswer: string,
    isHiddenWhenDependant: boolean,
    alsoUpdateTemplate: boolean
  ): Promise<OnboardingStep> {
    const pbPair = await this._transport.updateQuestion(
      questionName,
      configId,
      stepName,
      description.map((d) => d.toProtobuf()),
      protobufFromOnboardingQuestionKind(kind),
      choices.map((c) => c.toProtobuf()),
      isRequired,
      dependantQuestionName,
      dependantQuestionAnswer,
      isHiddenWhenDependant,
      alsoUpdateTemplate
    );
    // No need to invalidate, the replacing step is returned.
    return new GrpcOnboardingStep(pbPair.step);
  }

  async updateAnswer(
    questionName: string,
    configId: string,
    stepName: string,
    answer: OnboardingAnswer
  ): Promise<OnboardingStep> {
    const pbPair = await this._transport.updateAnswer(questionName, configId, stepName, answer.toProtobuf());
    // No need to invalidate, the affected step is returned.
    return new GrpcOnboardingStep(pbPair.step);
  }

  async prepareUploadFile(
    questionName: string,
    configId: string,
    stepName: string,
    fileName: string
  ): Promise<{ uploadUrl: string; downloadUrl: string; contentType: string }> {
    const pbResponse = await this._transport.prepareUploadFile(questionName, configId, stepName, fileName);

    const uploadUrl = pbResponse.uploadUrl;
    const downloadUrl = pbResponse.downloadUrl;
    const contentType = pbResponse.contentType;

    return { uploadUrl, downloadUrl, contentType };
  }

  async uploadQuestionFile(
    dataUrl: string,
    questionName: string,
    configId: string,
    stepName: string,
    fileName?: string
  ): Promise<string> {
    // Request an upload url.
    const { uploadUrl, downloadUrl, contentType } = await this.prepareUploadFile(
      questionName,
      configId,
      stepName,
      fileName ?? ''
    );

    // Upload the file data to that url.
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType
      },
      body: dataUrlToBlob(dataUrl)
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return downloadUrl;
  }

  async renameQuestion(
    questionName: string,
    configId: string,
    stepName: string,
    newQuestionName: string
  ): Promise<OnboardingStep> {
    const pbPair = await this._transport.renameQuestion(questionName, configId, stepName, newQuestionName);
    // No need to invalidate, the affected step is returned.
    return new GrpcOnboardingStep(pbPair.step);
  }

  async deleteQuestion(questionName: string, configId: string, stepName: string): Promise<OnboardingStep> {
    const pbStep = await this._transport.deleteQuestion(questionName, configId, stepName);
    // No need to invalidate, the updated step is returned.
    return new GrpcOnboardingStep(pbStep);
  }

  async getSensitiveAnswer(
    questionName: string,
    configId: string,
    stepName: string,
    includePreviousAnswers: boolean
  ): Promise<SensitiveAnswer> {
    const pbResponse = await this._transport.getSensitiveAnswer(
      questionName,
      configId,
      stepName,
      includePreviousAnswers
    );

    const pbAnswer = pbResponse.answer;

    return {
      answer: pbAnswer != null ? new GrpcOnboardingAnswer(pbAnswer) : undefined,
      previousAnswers: pbResponse.previousAnswers.map((a) => new GrpcOnboardingAnswer(a))
    };
  }

  async getProcessComments(processName: string, configId: string): Promise<OnboardingComment[]> {
    const pbComments = await this._transport.getComments(processName, undefined, configId);
    return pbComments.map((c) => new GrpcOnboardingComment(c));
  }

  async getStepComments(processName: string, stepName: string, configId: string): Promise<OnboardingComment[]> {
    const pbComments = await this._transport.getComments(processName, stepName, configId);
    return pbComments.map((c) => new GrpcOnboardingComment(c));
  }

  async addOrEditComment(comment: OnboardingComment): Promise<OnboardingComment> {
    const pbComment = await this._transport.addOrEditComment(comment.toProtobuf());
    return new GrpcOnboardingComment(pbComment);
  }

  async deleteComment(commentId: string): Promise<void> {
    await this._transport.deleteComment(commentId);
  }

  async getSchoolHistory(configId: string, languageCode: string): Promise<OnboardingHistoryEntry[]> {
    const pbEntries = await this._transport.getSchoolHistory(configId, languageCode);
    return pbEntries.map((e) => new GrpcOnboardingHistoryEntry(e));
  }

  async getDashboard(
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ): Promise<Dashboard> {
    const pbDashboard = await this._transport.getDashboard(
      processStatuses.map((s) => protobufFromOnboardingStatus(s)),
      stepStatuses.map((s) => protobufFromOnboardingStatus(s)),
      minimumDate != null ? protobufFromDate(minimumDate) : undefined
    );
    return new GrpcDashboard(pbDashboard);
  }

  async searchDashboard(
    searchTerm: string,
    processStatuses: OnboardingStatus[],
    stepStatuses: OnboardingStatus[],
    minimumDate: Date | undefined
  ): Promise<Dashboard> {
    const pbDashboard = await this._transport.searchDashboard(
      searchTerm,
      processStatuses.map((s) => protobufFromOnboardingStatus(s)),
      stepStatuses.map((s) => protobufFromOnboardingStatus(s)),
      minimumDate != null ? protobufFromDate(minimumDate) : undefined
    );
    return new GrpcDashboard(pbDashboard);
  }

  async getDashboardProcessesComments(minimumDate: Date): Promise<DashboardProcessComments[]> {
    const pbProcesses = await this._transport.getDashboardComments(protobufFromDate(minimumDate));

    return pbProcesses.map((pb) => new GrpcDashboardProcessComments(pb));
  }

  async prepareUploadResourceFile(
    configId: string,
    fileName: string
  ): Promise<{ uploadUrl: string; downloadUrl: string; contentType: string }> {
    const pbResponse = await this._transport.prepareUploadResourceFile(configId, fileName);

    const uploadUrl = pbResponse.uploadUrl;
    const downloadUrl = pbResponse.downloadUrl;
    const contentType = pbResponse.contentType;

    return { uploadUrl, downloadUrl, contentType };
  }

  async uploadResourceFile(dataUrl: string, configId: string, fileName?: string): Promise<string> {
    // Request an upload url.
    const { uploadUrl, downloadUrl, contentType } = await this.prepareUploadResourceFile(configId, fileName ?? '');

    // Upload the file data to that url.
    const response = await fetch(uploadUrl, {
      method: 'PUT',
      headers: {
        'Content-Type': contentType
      },
      body: dataUrlToBlob(dataUrl)
    });

    if (!response.ok) {
      throw new Error(response.statusText);
    }

    return downloadUrl;
  }
}
