import { NavigationService } from '@insights/services';
import { ImportSession } from '@shared/models/import';
import { NavigateFunctionAsync } from '@shared/utils';
import { AutomatedImportListViewModel } from './AutomatedImportListViewModel';

export interface ImportSessionInfo {
  readonly session: ImportSession;
  readonly automatedImports: AutomatedImportListViewModel;
}

export interface ImportSessionListViewModel {
  readonly sessions: ImportSessionInfo[];

  editSessionDetails: (session: ImportSession) => Promise<void>;
  navigateToSessionDetails: (sessionId: string, navigate: NavigateFunctionAsync) => Promise<void>;
  navigateToAutomatedImport: (
    sessionId: string,
    automatedImportId: string,
    navigate: NavigateFunctionAsync
  ) => Promise<void>;
  addSession: () => Promise<void>;
}

export class AppImportSessionListViewModel implements ImportSessionListViewModel {
  constructor(
    private readonly navigationService: NavigationService,
    private readonly configId: string,
    private readonly invalidateSessions: () => void,
    public readonly sessions: ImportSessionInfo[]
  ) {}

  async editSessionDetails(session: ImportSession): Promise<void> {
    const result = await this.navigationService.navigateToImportSessionEdit(session);

    if (result !== 'cancelled') {
      this.invalidateSessions();
    }
  }

  async navigateToSessionDetails(sessionId: string, navigate: NavigateFunctionAsync): Promise<void> {
    await this.navigationService.navigateToImportSession(this.configId, sessionId, navigate);
  }

  async navigateToAutomatedImport(
    sessionId: string,
    automatedImportId: string,
    navigate: NavigateFunctionAsync
  ): Promise<void> {
    await this.navigationService.navigateToAutomatedImportDetails(
      this.configId,
      sessionId,
      automatedImportId,
      navigate
    );
  }

  async addSession() {
    const result = await this.navigationService.navigateToImportSessionCreate(this.configId);

    if (result !== 'cancelled') {
      this.invalidateSessions();
    }
  }
}
