import { useInsightsServices } from '@insights/UseInsightsServicesHook';
import { FilteredStudentsBehaviorViewModel } from '@insights/viewmodels';
import { FilterList } from '@mui/icons-material';
import {
  Badge,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SxProps
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useRef, useState } from 'react';

// Empty grade levels are a possible value so we need another value as the "none".
const noGradeLevel = '🚫🤷🐇';

export interface StudentsFilterProps {
  sx?: SxProps;
  className?: string;
  viewModel: FilteredStudentsBehaviorViewModel;
}

export const StudentsFilter = observer(({ sx, className, viewModel }: StudentsFilterProps) => {
  const { localizationService } = useInsightsServices();
  const strings = localizationService.localizedStrings.insights.components.behaviourSummary;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Badge
      sx={sx}
      className={className}
      variant="dot"
      color="error"
      showZero={false}
      badgeContent={viewModel.hasFilter ? 1 : 0}
    >
      <IconButton ref={buttonRef} onClick={() => setIsOpen(true)}>
        <FilterList />
      </IconButton>

      <Dialog maxWidth="xs" fullWidth open={isOpen} onClose={() => setIsOpen(false)}>
        <DialogTitle>{strings.filtersTitle}</DialogTitle>
        <DialogContent>
          <Select
            fullWidth
            label={strings.gradeLevelFilterLabel}
            value={viewModel.gradeLevelFilter ?? noGradeLevel}
            onChange={(e) =>
              (viewModel.gradeLevelFilter = e.target.value === noGradeLevel ? undefined : e.target.value)
            }
            displayEmpty
          >
            <MenuItem value={noGradeLevel}>{strings.allGradeLevels}</MenuItem>
            {viewModel.availableGradeLevels.map((g) => (
              <MenuItem key={`grade-level-${g}`} value={g}>
                {g || strings.emptyGradeLevel}
              </MenuItem>
            ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)}>{strings.closeButton}</Button>
        </DialogActions>
      </Dialog>
    </Badge>
  );
});
