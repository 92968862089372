import { MetricsSideNavigation } from '@insights/components';
import { Box, SxProps } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { CSSProperties, useMemo } from 'react';
import { Outlet, useParams } from 'react-router';
import { useInsightsServices } from '../../UseInsightsServicesHook';

export interface MetricsLayoutProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const MetricsLayout = observer((props: MetricsLayoutProps) => {
  const { viewModelFactory } = useInsightsServices();
  const { sx = [], className, style } = props;

  const params = useParams();
  const configId = params.configId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSchoolConfiguration(configId), [configId]);

  return (
    <Box sx={sx} display="flex" flexDirection="row" className={className} style={style}>
      <MetricsSideNavigation demoMode={viewModel.demoMode} configId={viewModel.configId} />

      <Box flexGrow={1}>
        <Outlet />
      </Box>
    </Box>
  );
});
