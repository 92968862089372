import { BehaviorAggregationInfo, StudentBehaviorAggregationInfo } from '@insights/models';
import { getAllBehaviorAggregations } from '@insights/utils';
import { AccountModel } from '@shared/models/config';
import { computed, makeObservable } from 'mobx';
import { FilteredStudentsProvider } from './FilteredStudentsViewModel';

export class FilteredStudentsBehaviorAggregationProvider {
  constructor(
    private readonly _studentsProvider: FilteredStudentsProvider,
    private readonly _parentsByChildId: Record<string, AccountModel[]>
  ) {
    makeObservable(this);
  }

  @computed
  get behaviors(): StudentBehaviorAggregationInfo {
    return getAllBehaviorAggregations(this._studentsProvider.students, this._parentsByChildId);
  }
}

export interface BehaviorProvider {
  readonly behavior: BehaviorAggregationInfo;
}

export class OpensAppBehaviorProvider implements BehaviorProvider {
  constructor(private readonly _aggregationProvider: FilteredStudentsBehaviorAggregationProvider) {
    makeObservable(this);
  }

  @computed
  get behavior(): BehaviorAggregationInfo {
    return this._aggregationProvider.behaviors.opensAppBehavior;
  }
}

export class CompletesTasksBehaviorProvider implements BehaviorProvider {
  constructor(private readonly _aggregationProvider: FilteredStudentsBehaviorAggregationProvider) {
    makeObservable(this);
  }

  @computed
  get behavior(): BehaviorAggregationInfo {
    return this._aggregationProvider.behaviors.completesTasksBehavior;
  }
}

export class InvitesParentBehaviorProvider implements BehaviorProvider {
  constructor(private readonly _aggregationProvider: FilteredStudentsBehaviorAggregationProvider) {
    makeObservable(this);
  }

  @computed
  get behavior(): BehaviorAggregationInfo {
    return this._aggregationProvider.behaviors.invitesParentBehavior;
  }
}
