import {
  AccountList,
  CurrentPageInfo,
  LabeledValuePresenter,
  ObservablePresenter,
  PageFooter,
  PageHeaderBar,
  PageHeaderDetailBar,
  RouterLink,
  SectionName,
  StudentBehaviorAggregation,
  WeekPagingNavigation
} from '@insights/components';
import { Box, Card, Chip, Grid2, SxProps } from '@mui/material';
import { AccountUtils, isSxArray } from '@shared/components/utils';
import { sortBy } from 'lodash';
import { Observer, observer } from 'mobx-react-lite';
import { CSSProperties, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { RouteParamNames, RouteTemplates } from '../../../Routes';
import { useInsightsServices } from '../../../UseInsightsServicesHook';
import { cleanDiacritics } from '../../../utils';
import { PublishedTasksBySection } from './PublishedTasksBySection';
import { SectionsCourseOccurrencesStatus } from './SectionsCourseOccurrencesStatus';
import { WorkloadManagerBySection } from './WorkloadManagerBySection';

export interface SectionDetailsProps {
  sx?: SxProps;
  className?: string;
  style?: CSSProperties;
}

export const SectionDetails = observer((props: SectionDetailsProps) => {
  const { analyticsService, localizationService, reactRouterRouteService, viewModelFactory } = useInsightsServices();
  const { className, style, sx = [] } = props;
  const strings = localizationService.localizedStrings.insights.views.metrics.sections;

  const params = useParams();
  const configId = params.configId ?? '';
  const sectionId = params.sectionId ?? '';
  const viewModel = useMemo(() => viewModelFactory.createSectionDetails(configId, sectionId), [configId, sectionId]);

  useEffect(() => {
    analyticsService.trackPage({ name: 'Metrics: Section Details' });
  });

  return (
    <Box
      className={className}
      style={style}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column'
        },
        ...(isSxArray(sx) ? sx : [sx])
      ]}
    >
      <PageHeaderBar />
      <Box
        sx={{
          flex: 1
        }}
      >
        <ObservablePresenter
          sx={{ width: '100%', height: '100%', overflow: 'auto' }}
          data={viewModel.data}
          loadingMessage={strings.loadingDetailsMessage}
          errorMessage={strings.loadingDetailsErrorMessage}
          render={(data) => {
            const studentsViewModel = data.studentsViewModel;

            return (
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                {/* Section Info */}
                <PageHeaderDetailBar>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row'
                    }}
                  >
                    {/* Section title and teachers */}
                    <Box
                      sx={{
                        flex: 1
                      }}
                    >
                      {/* Section Title */}
                      <SectionName
                        sx={{ mb: 1 }}
                        title={data.sectionInfo.section?.title ?? ''}
                        size="big"
                        color={data.sectionInfo.section?.color}
                      />

                      {/* Teachers */}
                      {data.sectionInfo.teachers && data.sectionInfo.teachers.length > 0 && (
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            flexWrap: 'wrap'
                          }}
                        >
                          {sortBy(
                            data.sectionInfo.teachers.map((teacher) => ({
                              teacher: teacher.account,
                              displayName: AccountUtils.getDisplayLastFirstName(teacher.account, strings.noName)
                            })),
                            (teacherInfo) => [cleanDiacritics(teacherInfo.displayName).toLowerCase()]
                          ).map((teacherInfo) => (
                            <Box
                              key={teacherInfo.teacher.id}
                              sx={{
                                marginRight: 1,
                                marginBottom: 1
                              }}
                            >
                              <RouterLink
                                underline="none"
                                to={reactRouterRouteService.resolveLocation(RouteTemplates.teacherDetails, [
                                  {
                                    name: RouteParamNames.configId,
                                    value: viewModel.configId
                                  },
                                  {
                                    name: RouteParamNames.teacherId,
                                    value: teacherInfo.teacher.id
                                  }
                                ])}
                              >
                                <Chip
                                  key={teacherInfo.teacher.id}
                                  label={teacherInfo.displayName}
                                  clickable={true}
                                  sx={{ fontWeight: '300' }}
                                />
                              </RouterLink>
                            </Box>
                          ))}
                        </Box>
                      )}
                    </Box>

                    {/* Section information (group, grade, room and students) */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: 1,
                        marginRight: 1
                      }}
                    >
                      {data.sectionInfo.section != null && (
                        <>
                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.grade}
                            value={data.sectionInfo.section.gradeLevel}
                          />

                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.group}
                            value={data.sectionInfo.section.sectionNumber}
                          />

                          <LabeledValuePresenter
                            sx={{ ml: 4 }}
                            label={strings.room}
                            value={data.sectionInfo.section.defaultRoomName}
                          />
                        </>
                      )}

                      <LabeledValuePresenter
                        sx={{ ml: 4 }}
                        label={strings.studentsTitle}
                        value={(data.sectionInfo.students != null ? data.sectionInfo.students.length : 0).toString()}
                      />
                    </Box>
                  </Box>
                </PageHeaderDetailBar>
                {/* Content */}
                <Box
                  sx={{
                    flex: 1,
                    overflow: 'auto',
                    padding: 2
                  }}
                >
                  <Grid2
                    container
                    sx={{
                      alignItems: 'stretch'
                    }}
                  >
                    {/* Course Occurrences Status */}
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <SectionsCourseOccurrencesStatus
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createSectionsCourseOccurrencesStatus(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                          allowCsvExport
                        />
                      </Card>
                    </Grid2>

                    {/* Published Tasks */}
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <PublishedTasksBySection
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createPublishedTasksBySection(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                        />
                      </Card>
                    </Grid2>

                    {/* Workload Manager */}
                    <Grid2 size={{ xs: 12 }}>
                      <Card>
                        <WorkloadManagerBySection
                          sx={{ height: '100%', width: '100%' }}
                          viewModel={viewModelFactory.createWorkloadManagerBySection(
                            viewModel.configId,
                            [viewModel.sectionId],
                            viewModel.pagination
                          )}
                          displaySectionName={false}
                          displayWeekNavigation={false}
                        />
                      </Card>
                    </Grid2>

                    {/* Students */}
                    <Grid2 size={{ xs: 12 }}>
                      {studentsViewModel.hasStudents && (
                        <Card>
                          {/* Prevent full rerender without requiring separate component */}
                          <Observer>
                            {() => (
                              <Box
                                sx={{
                                  height: '100%',
                                  width: '100%'
                                }}
                              >
                                {studentsViewModel.opensAppBehavior != null &&
                                  studentsViewModel.completesTasksBehavior != null && (
                                    <StudentBehaviorAggregation
                                      opensApp={studentsViewModel.opensAppBehavior}
                                      completesTasks={studentsViewModel.completesTasksBehavior}
                                      invitesParent={studentsViewModel.invitesParentBehavior}
                                    />
                                  )}

                                <AccountList
                                  title={strings.studentsTitle}
                                  accounts={studentsViewModel.visibleStudents}
                                  accountType="student"
                                  configId={viewModel.configId}
                                  exportableViewModel={studentsViewModel}
                                />
                              </Box>
                            )}
                          </Observer>
                        </Card>
                      )}
                    </Grid2>
                  </Grid2>
                </Box>
                <PageFooter dense>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center'
                    }}
                  >
                    <CurrentPageInfo pagination={viewModel.pagination} />

                    <Box
                      sx={{
                        flex: 1
                      }}
                    />

                    <WeekPagingNavigation pagination={viewModel.pagination} />
                  </Box>
                </PageFooter>
              </Box>
            );
          }}
        />
      </Box>
    </Box>
  );
});
